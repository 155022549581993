export const GET_WH_SERVICES_REQUEST = 'GET_WH_SERVICES_REQUEST';
export const GET_WH_SERVICES_SUCCESS = 'GET_WH_SERVICES_SUCCESS';
export const GET_WH_SERVICES_FAILED = 'GET_WH_SERVICES_FAILED';

export const GET_WH_SERVICES_DASHBOARD_REQUEST = 'GET_WH_SERVICES_DASHBOARD_REQUEST';
export const GET_WH_SERVICES_DASHBOARD_SUCCESS = 'GET_WH_SERVICES_DASHBOARD_SUCCESS';
export const GET_WH_SERVICES_DASHBOARD_FAILED = 'GET_WH_SERVICES_DASHBOARD_FAILED';

export const GET_WH_SERVICES_AVAILABLE_REQUEST = 'GET_WH_SERVICES_AVAILABLE_REQUEST,';
export const GET_WH_SERVICES_AVAILABLE_SUCCESS = 'GET_WH_SERVICES_AVAILABLE_SUCCESS,';
export const GET_WH_SERVICES_AVAILABLE_FAILED = 'GET_WH_SERVICES_AVAILABLE_FAILED,';

export const GET_WH_SERVICES_BRANCH_REQUEST = 'GET_WH_SERVICES_BRANCH_REQUEST';
export const GET_WH_SERVICES_BRANCH_SUCCESS = 'GET_WH_SERVICES_BRANCH_SUCCESS';
export const GET_WH_SERVICES_BRANCH_FAILED = 'GET_WH_SERVICES_BRANCH_FAILED';

export const DELETE_WH_SERVICE_REQUEST = 'DELETE_WH_SERVICE_REQUEST';
export const DELETE_WH_SERVICE_SUCCESS = 'DELETE_WH_SERVICE_SUCCESS';
export const DELETE_WH_SERVICE_FAILED = 'DELETE_WH_SERVICE_FAILED';

export const DELETE_WH_SERVICE_BRANCH_REQUEST = 'DELETE_WH_SERVICE_BRANCH_REQUEST';
export const DELETE_WH_SERVICE_BRANCH_SUCCESS = 'DELETE_WH_SERVICE_BRANCH_SUCCESS';
export const DELETE_WH_SERVICE_BRANCH_FAILED = 'DELETE_WH_SERVICE_BRANCH_FAILED';

export const CREATE_WH_SERVICE_REQUEST = 'CREATE_WH_SERVICE_REQUEST';
export const CREATE_WH_SERVICE_SUCCESS = 'CREATE_WH_SERVICE_SUCCESS';
export const CREATE_WH_SERVICE_FAILED = 'CREATE_WH_SERVICE_FAILED';

export const CREATE_WH_SERVICE_IN_BRANCH_REQUEST = 'CREATE_WH_SERVICE_IN_BRANCH_REQUEST';
export const CREATE_WH_SERVICE_IN_BRANCH_SUCCESS = 'CREATE_WH_SERVICE_IN_BRANCH_SUCCESS';
export const CREATE_WH_SERVICE_IN_BRANCH_FAILED = 'CREATE_WH_SERVICE_IN_BRANCH_FAILED';

export const CREATE_WH_SERVICE_BRANCH_REQUEST = 'CREATE_WH_SERVICE_BRANCH_REQUEST';
export const CREATE_WH_SERVICE_BRANCH_SUCCESS = 'CREATE_WH_SERVICE_BRANCH_SUCCESS';
export const CREATE_WH_SERVICE_BRANCH_FAILED = 'CREATE_WH_SERVICE_BRANCH_FAILED';

export const UPDATE_WH_SERVICE_REQUEST = 'UPDATE_WH_SERVICE_REQUEST';
export const UPDATE_WH_SERVICE_SUCCESS = 'UPDATE_WH_SERVICE_SUCCESS';
export const UPDATE_WH_SERVICE_FAILED = 'UPDATE_WH_SERVICE_FAILED';

export const UPDATE_WH_SERVICE_IN_BRANCH_REQUEST = 'UPDATE_WH_SERVICE_IN_BRANCH_REQUEST';
export const UPDATE_WH_SERVICE_IN_BRANCH_SUCCESS = 'UPDATE_WH_SERVICE_IN_BRANCH_SUCCESS';
export const UPDATE_WH_SERVICE_IN_BRANCH_FAILED = 'UPDATE_WH_SERVICE_IN_BRANCH_FAILED';

export const COPY_WH_SERVICE_REQUEST = 'COPY_WH_SERVICE_REQUEST';
export const COPY_WH_SERVICE_SUCCESS = 'COPY_WH_SERVICE_SUCCESS';
export const COPY_WH_SERVICE_FAILED = 'COPY_WH_SERVICE_FAILED';
//Update whservice for branch
export const UPDATE_WH_SERVICE_BRANCH_REQUEST = 'UPDATE_WH_SERVICE_BRANCH_REQUEST';
export const UPDATE_WH_SERVICE_BRANCH_SUCCESS = 'UPDATE_WH_SERVICE_BRANCH_SUCCESS';
export const UPDATE_WH_SERVICE_BRANCH_FAILED = 'UPDATE_WH_SERVICE_BRANCH_FAILED';

//Update whservice for branch
export const ACCEPT_WH_SERVICE_BRANCH_REQUEST = 'ACCEPT_WH_SERVICE_BRANCH_REQUEST';
export const ACCEPT_WH_SERVICE_BRANCH_SUCCESS = 'ACCEPT_WH_SERVICE_BRANCH_SUCCESS';
export const ACCEPT_WH_SERVICE_BRANCH_FAILED = 'ACCEPT_WH_SERVICE_BRANCH_FAILED';

export const GET_WH_SERVICE_REQUEST = 'GET_WH_SERVICE_REQUEST';
export const GET_WH_SERVICE_SUCCESS = 'GET_WH_SERVICE_SUCCESS';
export const GET_WH_SERVICE_FAILED = 'GET_WH_SERVICE_FAILED';

export const COPY_WH_SERVICE_BY_BRANCH_REQUEST = 'COPY_WH_SERVICE_BY_BRANCH_REQUEST';
export const COPY_WH_SERVICE_BY_BRANCH_SUCCESS = 'COPY_WH_SERVICE_BY_BRANCH_SUCCESS';
export const COPY_WH_SERVICE_BY_BRANCH_FAILED = 'COPY_WH_SERVICE_BY_BRANCH_FAILED';

export const RESET_WH_SERVICE_STATE = 'RESET_WH_SERVICE_STATE';
export const RESET_WH_SERVICE_BRANCH_STATE = 'RESET_WH_SERVICE_BRANCH_STATE';
export const RESET_WH_SERVICE_IN_BRANCH_STATE = 'RESET_WH_SERVICE_IN_BRANCH_STATE';
