import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActionColumns,
  BaseBorderBox,
  Breadcrumb,
  WithPermission
} from '~/components/Common';
import TabBranch, { useContextBranch } from '~/components/Common/TabBranch';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { ROOT_COMPANYID } from '~/constants/defaultValue';
import POLICY from '~/constants/policy';
import { ACCEPT_TYPE, ACCEPT_TYPE_VI } from '~/constants/serviceBranch';
import {
  useAcceptWhServiceBranch,
  useCreateWhServiceBranch,
  useCopyWhServiceByBranch,
  useDeleteWhServiceBranch,
  useMatchPolicy,
  useProfile,
  useResetWhService,
  useUpdateWhServiceBranch,
  useUpdateWhServiceBranchQueryParams,
  useWhServiceBranchPaging,
  useWhServiceBranchQueryParams,
  useWhServicesBranch
} from '~/hooks';
import { get, head, join, keys } from 'lodash';
import FormRegisterService from './FormRegisterService';

import { useBranchIdSessionStorage, useOptionsSelectAntd } from '~/hooks/utils';
import FormDeny from './FormDeny';
import {
  CheckCircleOutlined,
  CloseCircleTwoTone,
  CopyOutlined,
  DeleteOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import api from '~/api';
import { filterOptionSlug } from '~/utils/helper';
import RegisterServiceSelect from './RegisterServiceSelect';
import DebounceSelectByCategory from '~/components/WhCategory/SearchByCategory';
import RegisterServiceSelectV2 from './RegisterServiceSelectV2';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { PATH_APP } from '~/routes/paths';
const { Search } = Input;

const ColumnActions = ({
  id,
  status,
  updateWhService,
  profile,
  deleteWhService,
  copyWhService,
  branchId,
  branchOwner,
  ...restProps
}) => {
  return (
    <div className="custom-table__actions">
      <Space direction="vertical">
        {branchOwner && <WithPermission permission={POLICY.UPDATE_SERVICEBRANCH}>
          <Link to={`/wh-service-branch/edit/${id}`}>
            <Button size="small" block icon={<InfoCircleOutlined />}>
              Cập nhật
            </Button>
          </Link>
        </WithPermission>}

        <WithPermission permission={POLICY.DELETE_SERVICEBRANCH}>
          <Popconfirm
            title="Bạn muốn xoá nhà cung cấp này?"
            onConfirm={() =>
              deleteWhService({
                id,
                branchId: branchId
              })
            }
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button block danger size="small" icon={<DeleteOutlined />}>
              Xoá
            </Button>
          </Popconfirm>
        </WithPermission>
        <WithPermission permission={POLICY.WRITE_SERVICEBRANCH}>
          <Popconfirm
            title="Bạn có chắc chắn muốn sao chép dịch vụ này?"
            onConfirm={()=>copyWhService(id)}
            // onCancel={cancel}
            okText="Đồng ý"
            cancelText="Huỷ"
            >
            <Button 
              size="small"  
              icon={<CopyOutlined />}
              block
              type="primary"
              >
              Sao chép
            </Button>
            </Popconfirm>
        </WithPermission>
      </Space>
    </div>
  );
};

export default function WhServiceBranch() {
  const [form] = Form.useForm();
  const optionsTypeAccept = useOptionsSelectAntd(ACCEPT_TYPE_VI);
  const canDelete = useMatchPolicy(POLICY.DELETE_SERVICEBRANCH);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_SERVICEBRANCH);
  const canAccept = useMatchPolicy(POLICY.UPDATE_ACCEPTSERVICE);
  const [query, onPagingChange] = useWhServiceBranchQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateWhServiceBranchQueryParams(query);
  const [whServices, isLoading] = useWhServicesBranch(query);
  const paging = useWhServiceBranchPaging();
  const [,copyWhServiceByBranch] = useCopyWhServiceByBranch()
  const [profile] = useProfile();
  const isRootCompanyId = useMemo(
    () => get(profile, 'branchId') === ROOT_COMPANYID,
    [profile]
  );
  const [submitLoading, updateWhService] = useUpdateWhServiceBranch(() => {
    onCloseDeny();
    handleClose();
  });
  const [isLoadingServiceBranch, createWhService] = useCreateWhServiceBranch(() => {
    onCloseDeny();
    handleClose();
  });

  const [, acceptWhServiceBranch] = useAcceptWhServiceBranch(() => {
    onCloseDeny();
    handleClose();
  });
  const [, deleteWhService] = useDeleteWhServiceBranch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeny, setIsOpenDeny] = useState(false);
  const [payloadDeny, setPayloadDeny] = useState();
  const [service, setService] = useState(null);
  const [branchId] = useBranchIdSessionStorage();
  const [infoService, setInfoService] = useState(null);
  useResetWhService();
  const handleOpen = (record) => {
    setIsOpen(true);
    setService(record);
  };

  function handleClose() {
    setIsOpen(false);
    setService(null);
    setInfoService(null);
  }
  const onOpenDeny = (payload) => {
    setIsOpenDeny(true);
    setPayloadDeny(payload);
  };

  function onCloseDeny() {
    setIsOpenDeny(false);
    setPayloadDeny(null);
  }

  const handleAccept = (isAccess, id, note) => {
    let payload = {
      isAccess,
      id,
      note
    };
    acceptWhServiceBranch(payload);
  };
  const columns = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        const filterAreaAvailble = get(record, 'areaAvailble', [])?.map(
          (item) => ({
            label: get(item, 'fullAddress', ''),
            value: get(item, 'path', '')
          })
        );
        return (
          <Button
            type="link"
            onClick={() => {
              handleOpen(record);
              setInfoService({
                service: {
                  ...record?.service,
                  whCategoryId: get(record, 'service.whCategoryId._id', '')
                },
                branchId: get(record, 'branchId'),
                areaAvailble: filterAreaAvailble,
                serviceId: get(record, 'serviceId')
              });
            }}
          >
            {get(record, 'service.code', '')}
          </Button>
        );
      }
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => get(record, 'service.name.vi', '')
    },
    {
      title: 'Tên dịch vụ chi tiết',
      key: 'detailName',
      render: (value, record) => get(record, 'service.detailName.vi', '')
    },
    {
      title: 'Nhóm dịch vụ',
      key: 'whCategoryId',
      render: (value, record) => {
        return (
          record?.service?.whCategoryId?.name?.vi ??
          record?.service?.whCategoryId?.name
        );
      }
    },
    {
      title: 'Khu vực hoạt động',
      dataIndex: 'areaAvailble',
      key: 'areaAvailble',
      render: (value, record) =>
        record?.areaAvailble?.map((item) => item?.fullAddress)?.join(', ')
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        return (
          <WithPermission permission={POLICY.UPDATE_SERVICEBRANCH}>
            <Switch
              checked={record?.status === 'ACTIVE'}
              onChange={(value) =>
                updateWhService({
                  status: value ? 'ACTIVE' : 'INACTIVE',
                  id: record?.serviceId
                })
              }
            />
          </WithPermission>
        );
      }
    },

    ...(canDelete || canUpdate
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '110px',
            render: (record) => {
              return (
                <ColumnActions
                  profile={profile}
                  {...record}
                  id={record?.serviceId}
                  updateWhService={updateWhService}
                  deleteWhService={deleteWhService}
                  copyWhService={copyWhServiceByBranch}
                  branchId={branchId}
                  branchOwner={get(record, "service.branchOwner")}
                />
              );
            }
          }
        ]
      : [])
  ];

  columns.push({
    title: 'Phê duyệt',
    key: 'actionApprove',
    dataIndex: 'isAccess',
    width: '110px',
    align: 'center',
    render: (isAccess, record) =>
      isAccess === ACCEPT_TYPE.NEW && isRootCompanyId ? (
        <Row>
          <Col span={24}>
            <Popconfirm
              title="Xác nhận phê duyệt?"
              onConfirm={() =>
                handleAccept(ACCEPT_TYPE.ACCEPT, record?.serviceId)
              }
              okText="Duyệt"
              cancelText="Huỷ"
              disabled={!canAccept}
            >
              <Button
                disabled={!canAccept}
                type="primary"
                className="rounded-3 d-flex align-items-center"
                icon={<CheckCircleOutlined />}
                block
              >
                Phê duyệt
              </Button>
            </Popconfirm>
          </Col>

          <Col span={24}>
            <Popconfirm
              title="Xác nhận từ chối?"
              onConfirm={() =>
                onOpenDeny({
                  id: record?.serviceId,
                  isAccess: ACCEPT_TYPE.DENY
                })
              }
              okText="Từ chối"
              cancelText="Huỷ"
              disabled={!canAccept}
            >
              <Button
                danger
                disabled={!canAccept}
                className="rounded-3 d-flex align-items-center mt-1"
                block
                icon={<CloseCircleTwoTone twoToneColor={'red'} />}
              >
                Từ chối
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ) : (
        <Popconfirm
          disabled={!get(record, 'note', '')}
          placement="topRight"
          overlayClassName="popConfirm-custom hide-action"
          title={
            <BaseBorderBox title={'Lý do từ chối'}>
              {get(record, 'note', '')}
            </BaseBorderBox>
          }
          icon={null}
        >
          {!isRootCompanyId && isAccess === ACCEPT_TYPE.NEW ? (
            <span className={`bill-status ${isAccess?.toLowerCase()}`}>
              Đang chờ duyệt...
            </span>
          ) : (
            <Badge dot={get(record, 'note', '')}>
              <span
                className={`bill-status ${
                  isAccess === ACCEPT_TYPE.DENY && 'cursor-pointer'
                } ${isAccess?.toLowerCase()}`}
              >
                {ACCEPT_TYPE_VI[isAccess]}
              </span>
            </Badge>
          )}
        </Popconfirm>
      )
  });

  const onValuesChange = (value) => {
    const { isAccess, whCategoryIds } = value;
    const keyChange = head(keys(value));
    switch (keyChange) {
      case 'isAccess':
        onParamChange({ isAccess: join(isAccess, ',') });
        break;
      case 'whCategoryIds':
        onParamChange({ whCategoryIds: join(whCategoryIds, ',') });
        break;

      default:
        break;
    }
  };

  return (
    <div className="page-wraper page-content wh-bills-page">
      <TabBranch>
        <div className="container-fluid">
          <Breadcrumb title="Đăng kí hoạt động dịch vụ" />

          <div className="page-content__main">
            <div className="page-content__left">
              <Form
                form={form}
                autoComplete="off"
                onFinish={() => {}}
                scrollToFirstError
                onValuesChange={onValuesChange}
                requiredMark={false}
                labelCol={{ sm: 24, md: 24, lg: 24 }}
              >
                <Form.Item label="Trạng thái phê duyệt" name="isAccess">
                  <Select
                    placeholder="Chọn trạng thái..."
                    mode="multiple"
                    allowClear
                    options={optionsTypeAccept}
                  />
                </Form.Item>
                <SelectMultiWhCategory
                  form={form}
                  query={query}
                  onParamChange={onParamChange}
                />
              </Form>
            </div>

            <div className="page-content__right">
              <div className="page-wraper__header">
                <Row justify="start" gutter={36}>
                  <Col span={12}>
                    <Search
                      allowClear
                      style={{ width: 300 }}
                      placeholder="Tìm dịch vụ, mã dịch vụ"
                      enterButton
                      onSearch={(value) =>
                        onParamChange({ keyword: value?.trim() })
                      }
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </Col>
                  {/* <WithPermission permission={POLICY.WRITE_SERVICEBRANCH}>
                    <Col span={8} align="end">
                      <Link to={PATH_APP.whServiceBranch.create}>
                        <Button type="primary" style={{ float: 'right' }}>
                          {' '}
                          Thêm mới
                        </Button>
                      </Link>
                    </Col>
                  </WithPermission> */}
                  <WithPermission permission={POLICY.WRITE_SERVICEBRANCH}>
                   <Col sm={24} md={12} align="end" >
                      <Link to={PATH_APP.whServiceBranch.create}>
                        <Button type="primary" style={{ float: 'right', marginLeft: '8px' }}>
                          {' '}
                          Thêm mới
                        </Button>
                      </Link>
                      <Button type="primary" onClick={() => setIsOpen(true)} style={{ float: 'right', marginRight: '8px' }}>
                        Đăng ký
                      </Button>
                    </Col>
                  </WithPermission>
                </Row>
              </div>
              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={5} />
              ) : (
                <Table
                  columns={columns}
                  dataSource={whServices || []}
                  size="middle"
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  onChange={(cur) => {
                    onParamChange({ page: cur?.current, limit: cur?.pageSize });
                  }}
                />
              )}
            </div>

            <ElementCustom
              isOpen={isOpen}
              handleClose={handleClose}
              updateWhService={updateWhService}
              infoService = {infoService}
              createWhService = {createWhService}
            />
          </div>
        </div>
        <Modal
          visible={isOpenDeny}
          onCancel={onCloseDeny}
          footer={null}
          closable={null}
        >
          <FormDeny
            isLoading={submitLoading}
            onCancel={onCloseDeny}
            onSubmit={(values) =>
              acceptWhServiceBranch({ ...payloadDeny, ...values })
            }
          />
        </Modal>
      </TabBranch>
    </div>
  );
}

const ElementCustom = ({ children, isOpen, handleClose, updateWhService, infoService,createWhService }) => {
  const { branches } = useContextBranch();
  return (
    <>
      {children}
      <Modal
          destroyOnClose
          style={{ minWidth: '1200px'}}
          width={'auto'}
          title={`${infoService ? 'Cập nhật' : 'Đăng ký'} hoạt động cho dịch vụ`}
          visible={isOpen}
          footer={null}
          onCancel={() => handleClose()}
      >
        <RegisterServiceSelectV2
          onCancel={() => handleClose()}
          branches={branches}
          dataSource={infoService}
          updateWhService={updateWhService}
          createWhService={createWhService}
        />
      </Modal>
    </>
  );
};

function SelectMultiWhCategory({ form, query, onParamChange }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [whCategory, setWhCategory] = useState(null);
  // const fetch = useCallback(async() => {
  // try {
  //   form.resetFields();
  //   setLoading(true);
  //   const whCategory = await api.whCategory.getWhCategoryByAuthenByPermission();
  //   setOptions(whCategory?.map(item => ({
  //     label : get(item,'name',''),
  //     value : get(item,'key','')
  //   })));
  //   setLoading(false);
  // } catch (error) {
  //   setLoading(false);
  // }
  // },[form])
  // useEffect(() => fetch(), [fetch]);
  useEffect(() => {
    if (!whCategory && query?.whCategory) {
      // const splitWhCategory = query?.whCategory.split(',');
      setWhCategory(query?.whCategoryIds);
    }
  }, [query]);

  return (
    <Form.Item label="Nhóm dịch vụ" name="whCategoryIds">
      {/* <Select
  loading={loading}
  filterOption={filterOptionSlug}
   placeholder='Chọn nhóm dịch vụ...' mode='multiple' allowClear options={options} /> */}
      <DebounceSelectByCategory
        onChange={(e) => {
          setWhCategory(e);
          onParamChange({ whCategoryIds: e });
        }}
        value={whCategory}
        isFirstFetch={!!whCategory}
        placeholder="Tìm nhóm dịch vụ"
        style={{ minWidth: '200px' }}
      />
    </Form.Item>
  );
}
