import React, { useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import {
  FilterDatePicker,
  WithPermission,
  WithPermissionDisabled
} from '~/components/Common';
import { Button, Col, Input, Row, Select, Table, Modal,Form } from 'antd';
import WhPartnersImportForm from './Form';
import ImportForm from './ImportForm';
import {
  useWhPartnersImport,
  useDeleteWhPartnerImport,
  useUpdateWhPartnerImport,
  useWhPartnersImportQueryParams,
  useUpdatePartnerImportParams,
  useWhPartnerImportPaging,
  useConvertWhPartnerImport
} from '~/hooks';
import { useSpecialityOptions } from '~/hooks/speciality';
import './index.scss';
import MenuAction from './MenuAction';

import api from '~/api';
import { WH_PARTNER_STATUS_ACTION } from '~/constants/defaultValue';
import POLICY from '~/constants/policy';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import TabBranch from '../Common/TabBranch';

const { Search } = Input;

const WhPartnersImport = () => {
  const [searchBy, setSearchBy] = useState('fullName');
  const [query, onPagingChange] = useWhPartnersImportQueryParams(searchBy);
  const [, convertWhPartnerImport] = useConvertWhPartnerImport();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdatePartnerImportParams(query);
  const specialityOptions = useSpecialityOptions();
  const [, deletePartner] = useDeleteWhPartnerImport();
  const [whPartnersImport, isLoading] = useWhPartnersImport(query);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [importId, setImportId] = useState(null);
  const paging = useWhPartnerImportPaging();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleClose = () => {
    setImportId(null);
    setIsOpen(false);
  };

  const onSelectPartner = (item, event) => {
    event?.preventDefault();
    setSelectedPartner(item);
    setIsOpen(true);
    setImportId(item.id);
  };
  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => {
        return (query.page - 1) * query.limit + index + 1;
      }
    },
    {
      title: 'Mã đối tác',
      key: 'index',
      width: '100px',
      render: (record) => {
        const regex = /[null]/;
        const tabNumberSearch =
          record?.statusActive === 'CONVERTED'
            ? 'tabs=1'
            : 'waitingApproval=true&tabs=2';
        return (
          <Button
            onClick={(event) =>
              history.push({
                pathname: '/wh-partner',
                search: `page=1&limit=10&code=${record.partnerCodePotential}&${tabNumberSearch}`
              })
            }
            style={{
              background: 'none',
              border: 'none',
              padding: '0!important',
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#1890ff'
            }}
          >
            {!regex.test(record.partnerCodePotential) ? record.partnerCodePotential : ''}
          </Button>
        );
      }
    },
    {
      title: 'Họ và tên',
      key: 'fullName',
      width: '300px',
      render: (item) => item.fullName
    },

    {
      title: 'Số điện thoại',
      key: 'phoneNumber',
      width: '150px',
      render: (record) => {
        return record.phoneNumber;
      }
    },

    {
      title: 'Email',
      key: 'email',
      with: '350px',
      render: (record) => {
        return record.email;
      }
    },
    {
      title: 'Lời nhắn',
      key: 'message',
      with: '200px',
      render: (record) => {
        return record.message;
      }
    },
    {
      title: 'Mã giới thiệu',
      key: 'note',
      width: '200px',
      render: (record) => {
        return record.note;
      }
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '200',
      render: (record) => {
        return moment(record).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Trạng thái',
      key: 'statusActive',
      width: '3%',
      align: 'center',
      render: (record) =>
        record ? (
          <span
            className={`partner-status ${(record?.statusActive).toLowerCase()}`}
          >
            {WH_PARTNER_STATUS_ACTION[record?.statusActive]}
          </span>
        ) : (
          ''
        )
    },
    {
      title: 'Action',
      key: 'createdAt',
      fixed: 'right',
      dataIndex: 'createdAt',
      width: '150px',
      render: (text, record, index) => {
        return (
          <MenuAction
            titleDelete={'Bạn có muốn xoá đối tác này ?'}
            titleConverter={
              'Bạn có muốn chuyển đối tác này trở thành chính thức ?'
            }
            record={record}
            updateAction={async () => {
              const res = await api.whPartnerImport.getById(record._id);
              const idPartner = res.data.id;
              setImportId(idPartner);
              setIsOpen(true);
            }}
            deleteAction={async () => {
              const res = await api.whPartnerImport.getById(record.id);
              const idPartner = res.data.id;
              deletePartner(idPartner);
            }}
            convertAction={async () => {
              const res = await api.whPartnerImport.getById(record.id);
              const idPartner = res.data.id;
              convertWhPartnerImport(idPartner);
            }}
          />
        );
      }
    }
  ];

  return (
    <div className="page-wrapper page-content whPartnerImports-page">
      <div className="container-fluid">
        <TabBranch useBoxShadow={false}>
          <Breadcrumb title="Đối tác tiềm năng" />
          <div className="page-wraper__header">
            {/* Search Input*/}

            <Row justify="start" gutter={36}>
              <Col sm={24} md={6}>
                <Select
                  value={searchBy}
                  style={{ width: '100%' }}
                  onChange={(val) => setSearchBy(val)}
                >
                  <Option value="partnerCodePotential">Mã đối tác</Option>
                  <Option value="fullName">Họ và tên</Option>
                  <Option value="phoneNumber">Số điện thoại</Option>
                  {/* <Option value="dateOfBirth">Năm sinh</Option> */}
                </Select>
              </Col>

              <Col sm={24} md={6}>
                {
                  {
                    partnerCodePotential: (
                      <Search
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Tìm theo mã khách hàng"
                        enterButton
                        onSearch={() => onParamChange({ [searchBy]: keyword })}
                        onChange={(e) => setKeyword(e.target.value)}
                        value={keyword}
                      />
                    ),
                    fullName: (
                      <Search
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Tìm theo tên"
                        enterButton
                        onSearch={() => onParamChange({ [searchBy]: keyword })}
                        onChange={(e) => setKeyword(e.target.value)}
                        value={keyword}
                      />
                    ),
                    phoneNumber: (
                      <Search
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Tìm theo số điện thoại"
                        enterButton
                        onSearch={() => onParamChange({ [searchBy]: keyword })}
                        onChange={(e) => setKeyword(e.target.value)}
                        value={keyword}
                      />
                    )
                  }[searchBy]
                }
              </Col>

              <Col sm={24} md={12}>
                <WithPermission permission={POLICY.WRITE_POTENTIALPARTNER}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                      setImportId(null);
                      setIsOpen(true);
                    }}
                  >
                    Thêm mới
                  </Button>
                  <div>
                    <Button
                      type="primary"
                      style={{ float: 'right', marginRight: '10px' }}
                      onClick={showModal}
                    >
                      {' '}
                      Import
                    </Button>
                  </div>
                </WithPermission>
              </Col>
            </Row>
          </div>
          {isLoading ? (
            <SkeletonTable
              columns={columns}
              rowCount={whPartnersImport.length}
              pagination={paging}
            />
          ) : (
            <Table
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={whPartnersImport}
              onChange={({ current }) => onParamChange({ page: current })}
              size="middle"
              scroll={{ x: 'auto' }}
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `
              }}
            />
          )}
        </TabBranch>
      </div>

      <Modal
        width={1000}
        visible={isOpen}
        onCancel={handleClose}
        onOk={handleClose}
        footer={null}
        destroyOnClose
      >
        <WhPartnersImportForm
          specialityOptions={specialityOptions}
          id={importId}
          onClose={handleClose}
          onCancel={handleCancel}
        />
      </Modal>

      <ImportForm
        onParamChange={onParamChange}
        onModule={handleOk}
        isModalOpen={isModalOpen}
        onClose={handleCancel}
      />
    </div>
  );
};

export default WhPartnersImport;
