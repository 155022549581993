import produce from 'immer';
import { compact, get } from 'lodash';
import {
  GET_BRANCHES_REQUEST,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAILED,
  GET_BRANCH_REQUEST,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAILED,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_SUCCESS,
  CREATE_BRANCH_FAILED,
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILED,
  RESET_BRANCH_STATE,
  RESET_STORE,
  GET_RESOURCE_BRANCH_REQUEST,
  GET_RESOURCE_BRANCH_SUCCESS,
  GET_RESOURCE_BRANCH_FAILED,
  ACCESS_RESOURCE_BRANCH_REQUEST,
  ACCESS_RESOURCE_BRANCH_SUCCESS,
  ACCESS_RESOURCE_BRANCH_FAILED,
  DENY_RESOURCE_BRANCH_REQUEST,
  DENY_RESOURCE_BRANCH_SUCCESS,
  DENY_RESOURCE_BRANCH_FAILED,
  GET_RESOURCE_ACCESSED_BRANCH_REQUEST,
  GET_RESOURCE_ACCESSED_BRANCH_SUCCESS,
  GET_RESOURCE_ACCESSED_BRANCH_FAILED,
  ASSIGN_CATEGORY_BRANCH_REQUEST,
  ASSIGN_CATEGORY_BRANCH_SUCCESS,
  ASSIGN_CATEGORY_BRANCH_FAILED,
  REMOVE_CATEGORY_BRANCH_REQUEST,
  REMOVE_CATEGORY_BRANCH_SUCCESS,
  REMOVE_CATEGORY_BRANCH_FAILED,
} from '~/constants/actionTypes';
import { ACTIONS, CORE_ACTION } from '~/constants/policy';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,
  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  assignCategoryBranchFailed: null,

  removeCategoryBranchFailed: null,

  availableTimes: [],
  isGetAvailableTimesLoading: false,
  getAvailableTimesFailed: null,

  resourceBranch: [],
  isGetResourceBranchLoading: false,
  getResourceBranchFailed: null,

  resourceAccessedBranch: null,
  resourceAccessedBranchPre: null, // To Roll Back
  isGetResourceAccessedBranchLoading: false,
  getResourceAccessedBranchFailed: null,

  accessResourceSuccess: null,
  accessResourceFailed: null,

  denyResourceSuccess: null,
  denyResourceFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  const cloneResourceAccessedBranch = {...state.resourceAccessedBranch};
  const cloneResourceAccessedBranchPre = {...state.resourceAccessedBranchPre};
  switch (type) {
    case GET_BRANCHES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case GET_BRANCHES_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      // state.list = payload.docs;
      // state.paging = getPaging(payload);
      return;

    case GET_BRANCHES_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;

    case GET_BRANCH_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case GET_BRANCH_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case GET_BRANCH_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case DELETE_BRANCH_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_BRANCH_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_BRANCH_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_BRANCH_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.list = state.list.map(item => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item
      })
      return;

    case ASSIGN_CATEGORY_BRANCH_REQUEST:
      state.byId = {
        ...state.byId,
        whCategoryBranch : [...get(state.byId,'whCategoryBranch',[]),{whCategoryId : payload?.whCategoryId}] // Update New Data When Request
      }
      return;

    case ASSIGN_CATEGORY_BRANCH_SUCCESS:
      return;

    case ASSIGN_CATEGORY_BRANCH_FAILED:
      state.assignCategoryBranchFailed = payload;
      return;

    case REMOVE_CATEGORY_BRANCH_REQUEST:

    case ASSIGN_CATEGORY_BRANCH_REQUEST:
      state.byId = {
        ...state.byId,
        whCategoryBranch  : get(state.byId,'whCategoryBranch',[])?.filter((item) => item?.whCategoryId !== payload?.whCategoryId) // Update New Data When Request
      }
      return;

    case REMOVE_CATEGORY_BRANCH_SUCCESS:
      return;

    case REMOVE_CATEGORY_BRANCH_FAILED:
      state.removeCategoryBranchFailed = payload;
      return;


      case GET_RESOURCE_BRANCH_REQUEST:
      state.isGetResourceBranchLoading = true;
      state.getResourceBranchFailed = null;
      return;
    
      case GET_RESOURCE_BRANCH_SUCCESS:
      state.isGetResourceBranchLoading = false;
      state.resourceBranch = payload;
      return;

      case GET_RESOURCE_BRANCH_FAILED:
      state.isGetResourceBranchLoading = false;
      state.getResourceBranchFailed = payload;
      return;


      case GET_RESOURCE_ACCESSED_BRANCH_REQUEST:
      state.isGetResourceAccessedBranchLoading = true;
      state.getResourceAccessedBranchFailed = null;
      return;
    
      case GET_RESOURCE_ACCESSED_BRANCH_SUCCESS:
      state.isGetResourceAccessedBranchLoading = false;
      state.resourceAccessedBranch = payload;
      state.resourceAccessedBranchPre = payload;
      return;

    case GET_RESOURCE_ACCESSED_BRANCH_FAILED:
      state.isGetResourceAccessedBranchLoading = false;
      state.getResourceAccessedBranchFailed = payload;
      return;

      case ACCESS_RESOURCE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.accessResourceSuccess = null;
      state.accessResourceFailed = null;

      // Action Change State Request
      const {action,resource} = payload;
      let newResource = [];
      if(action === CORE_ACTION.ADMIN.toLowerCase()){
        newResource = compact([...get(cloneResourceAccessedBranch,resource,[]),...ACTIONS]);
      }else{
        newResource = [...get(cloneResourceAccessedBranch,resource,[]),action];
      }
      state.resourceAccessedBranch = {
        ...cloneResourceAccessedBranch,
        [resource] : newResource
      }
      return;

    case ACCESS_RESOURCE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.accessResourceSuccess = payload;

      // Action Change Pre State === State
      state.resourceAccessedBranchPre = {...cloneResourceAccessedBranch};
      return;

    case ACCESS_RESOURCE_BRANCH_FAILED:
      state.isSubmitLoading = false;
      state.accessResourceFailed = payload;

      // Roll back After Failed
      state.resourceAccessedBranch = {...cloneResourceAccessedBranchPre}
      return;

      case DENY_RESOURCE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.denyResourceSuccess = null;
      state.denyResourceFailed = null;

      // Action Change State Request
      const {action : actionDeny,resource : resourceDeny} = payload;
      let newResourceDeny = [];
      if(actionDeny !== CORE_ACTION.ADMIN.toLowerCase()){
        newResourceDeny = get(cloneResourceAccessedBranch,resourceDeny,[])?.filter((act) => act !== actionDeny)
      }
      state.resourceAccessedBranch = {
        ...cloneResourceAccessedBranch,
        [resourceDeny] : newResourceDeny
      }
      return;

    case DENY_RESOURCE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.denyResourceSuccess = payload;

      // Action Change Pre State === State
      state.resourceAccessedBranchPre = {...cloneResourceAccessedBranch};
      return;

    case DENY_RESOURCE_BRANCH_FAILED:
      state.isSubmitLoading = false;
      state.denyResourceFailed = payload;

      // Roll back After Failed
      state.resourceAccessedBranch = {...cloneResourceAccessedBranchPre}
      return;


    case RESET_BRANCH_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
