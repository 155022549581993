import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Menu, Popconfirm, Skeleton } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import toastr from 'toastr';
import TableStickyTop from '~/components/Common/TableStickyTop';
import WithPermission from '~/components/Common/WithPermission';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import POLICY from '~/constants/policy';
import {
  getNewPolicies,
  onSearchPermissions,
  useDeleteEmployeeGroup,
  useEmployeeGroup,
  useEmployeeGroups,
  useResetEmployeeGroup,
  useResourceAccessedBranch,
  useResourceColumns,
  useResources,
  useUpdateGroupPermission
} from '~/hooks';
import { Permissions } from '~/hooks/utils';
import GroupForm from '../GroupForm';
const Permission = ({ isActive, onChange,disabled }) => {
  return (
    <Checkbox
      checked={isActive}
      onChange={onChange}
      disabled={disabled}
    ></Checkbox>
  );
};

const getNextPath = (url) => {
  const paths = url.split('/');
  const nextPath = paths
    .filter((x, index) => !!x && index !== paths.length - 1)
    .join('/');

  return `/${nextPath}`;
};

const EmployeeGroups = () => {
  const { id: branchId, groupId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const branchIdParam = useMemo(() => ({ branchId }), [branchId]);

  const {canUpdate} = Permissions("USERGROUP")
  const [groups, isLoading] = useEmployeeGroups(branchIdParam);
  // const [resourceAccessed, loadingResourceAccessed] = useResourceAccessedBranch(branchId);
  const [resources, isResourcesLoading] = useResources();
  const [dataShow,setDataShow] = useState(null)
  useResetEmployeeGroup();
  useEffect(() => {
    if (!groupId && groups.length) {
      history.push(`${url}/${groups[0]._id}`);
    }
  }, [groups, history, url, groupId]);

  const params = useMemo(() => {
    return groupId ? { groupId, branchId } : null;
  }, [groupId, branchId]);



  const [group,isLoadingGroup,updateGroup] = useEmployeeGroup(params);

  const onSelectGroup = ({ key }) => {
    const nextPath = `${getNextPath(url)}/${key}`;
    history.push(nextPath);
  };

  const deleteCallback = () => {
    history.push(getNextPath(url));
  };

  const [isUpdateLoading, handleUpdate] = useUpdateGroupPermission();
  const [_, deleteGroup] = useDeleteEmployeeGroup(deleteCallback);

  const [initGroup, setInitGroup] = useState(group);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const onOpenForm = (group) => {
    setInitGroup(group);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setInitGroup({});
    setIsOpenForm(false);
  };

  const onPermisionChange = (isAssgined, resource, action) => {
    try {
      if(!canUpdate) return
      const newPolicies = getNewPolicies(group,isAssgined, resource, action)
      updateGroup({...group,policies :newPolicies }) // update Group in store redux
      handleUpdate({ isAssgined, resource, action, groupId });
    } catch (error) {
      toastr.error(get(error,'message','Some error'))
    }
  };
  const renderPermission = (action, rc) => {
    const resourceKey = rc.resource.key;
    const actionKey = action.key;
    const admin = group?.policies?.[resourceKey]?.['admin'];
    const notAccess = false; // Holding Feature
    // const notAccess = !get(resourceAccessed,resourceKey,[])?.includes(actionKey); // After Holding Will UnComment 
    return (
      notAccess ? <></> : <Permission
        isActive={group?.policies?.[resourceKey]?.[actionKey]}
        onChange={(event) =>
          onPermisionChange(event.target.checked, resourceKey, actionKey)
        }
        disabled={(!canUpdate || admin) && actionKey !== 'admin'}
      />
    );
  };

  const columns = useResourceColumns(renderPermission);

  return (
    <Card>
      <CardBody>
        <div className="employee-group">
          <div className="employee-group__list">
            <h5 className="employee-group__list-title">Nhóm người dùng</h5>
            <Menu
              className='employee-group__list__menu'
              defaultSelectedKeys={['1']}
              selectedKeys={[groupId]}
              mode="inline"
              theme="light"
              onSelect={onSelectGroup}
            >
              {isLoading
                ? [1, 2, 3, 4].map((index) => (
                    <Skeleton.Input
                      active
                      key={index}
                      style={{ marginBottom: 10 }}
                    />
                  ))
                : groups.map(({ name, _id }) => (
                    <Menu.Item key={_id}>{name} </Menu.Item>
                  ))}
            </Menu>
          </div>

          <div className="employee-group__permission">
            <div className="employee-group__header">
              <h5 className="employee-group__list-title ">
                Thiết lập quyền {group && `nhóm ${group.name}`}
              </h5>
              <WithPermission permission={POLICY.DELETE_USERGROUP}>
                <Popconfirm
                  title="Bạn muốn xoá chi nhánh này?"
                  onConfirm={() => deleteGroup(group._id)}
                  okText="Xoá"
                  cancelText="Huỷ"
                >
                  <Button size="small" type="danger">
                    <DeleteOutlined /> Xoá
                  </Button>
                </Popconfirm>{' '}
              </WithPermission>

              <WithPermission permission={POLICY.UPDATE_USERGROUP}>
                <Button
                  size="small"
                  onClick={() => onOpenForm(group)}
                  type="primary"
                >
                  <EditOutlined /> Cập nhật
                </Button>
              </WithPermission>

              <WithPermission permission={POLICY.WRITE_USERGROUP}>
                <Button
                  size="small"
                  onClick={() => onOpenForm({})}
                  type="primary"
                >
                  <PlusOutlined /> Tạo mới
                </Button>
              </WithPermission>
            </div>
            <div className='employee-group__search'>
                  <Search
                   placeholder='Tìm tên quyền'
                   enterButton
                   allowClear
                   onChange={(e) => onSearchPermissions(e.target.value,resources,setDataShow)}
                   />
            </div>
            {isResourcesLoading ? (
              <SkeletonTable columns={columns} />
            ) : (
              <TableStickyTop
                columns={columns}
                dataSource={dataShow ?? resources} // use dataShow when Search , Null will show resources , [] will show Empty
                size="small"
                rowKey={(rc) => rc.resource.key}
                pagination={{ hideOnSinglePage: true }}
              />
            )}
          </div>
        </div>
        <GroupForm
          isOpen={isOpenForm}
          onClose={onCloseForm}
          initGroup={initGroup}
        />
      </CardBody>
    </Card>
  );
};

export default EmployeeGroups;
