import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import React, { useEffect } from 'react'
import { Input } from 'reactstrap'

export default function SearchInputCustom({ placeholder, value, onChange,style }) {


  return (
    <div style={{ display: 'flex', gap: 5, padding: '5px 10px', borderRadius: 5,alignItems : 'center',border : '1px solid #d9d9d9',...style }}>
      <SearchOutlined style={{color : '#d9d9d9'}}/>
      <span style={{ color: 'rgba(15, 34, 58, 0.12)' }}>|</span>
      <Input style={{flex : 1,border : 0,height : 20}} size="small" placeholder={placeholder} value={value} onChange={(e) => onChange && onChange(e.target.value)} />
    </div>
  )
}
