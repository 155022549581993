import { Form, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import TextArea from 'antd/lib/input/TextArea';
import { get, set } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { FilterDatePicker } from '~/components/Common';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { HISTORY_SERVICE_BY_LOCATION_KEY } from '~/constants/defaultValue';
import {
  useHistoriesServiceByLocation,
  useHistoriesServiceByLocationPaging,
  useHistoriesServiceByLocationQueryParams,
  useUpdateHistoriesServiceByLocationParams
} from '~/hooks/history';

const WhServiceByLocation = () => {
  const [query, onTableChange] = useHistoriesServiceByLocationQueryParams();

  const paging = useHistoriesServiceByLocationPaging();
  const [keyword, setKeyword] = useState('');
  const [valueSearch, setValueSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [form] = Form.useForm();
  const newQuery = useMemo(
    () => ({
      ...query,
      keyword: keyword.trim(),
      startDate: startDate,
      endDate: endDate
    }),
    [query, keyword, startDate, endDate]
  );
  const [historiesServiceByLocation, isLoading] = useHistoriesServiceByLocation(newQuery);
  // const onSearch = () => {
  //   setKeyword(valueSearch ? valueSearch?.trim() : '');
  // };
  const onValuesChange = (values) => {};

  const columns = [
    {
      title: 'Người chỉnh sửa',
      key: 'fullName',
      dataIndex: 'fullName',
      render: (value, record) => get(record, 'actionBy.fullName') ?? '',
      width: 200,
      fixed: 'left'
    },
    {
      title: 'Loại nội dung',
      key: 'logType',
      dataIndex: 'logType',
      render: (logType) => HISTORY_SERVICE_BY_LOCATION_KEY[logType] ?? '',
      width: 200,
      align: 'center'
    },
    {
      title: 'Thời gian',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
      width: 300
    },
    {
      title: 'Nội dung',
      dataIndex: 'message',
      key: 'message',
      width: 500,
      render: (message) => {
        return (
          <TextArea
            value={message}
            readOnly
            autoSize={{ minRows: 2, maxRows: 6 }}
            scroll={{ x: '200' }}
          />
        );
      }
    }
  ];
  return (
    <div
      className="page-wraper page-content wh-bills-page"
      style={{ marginTop: 0 }}
    >
      {/* <div className="container-fluid"> */}
      <div className="page-content__main">
        <div className="page-content__left" style={{ width: '18%' }}>
          <Form
            form={form}
            autoComplete="off"
            onFinish={() => {}}
            scrollToFirstError
            // onValuesChange={onValuesChange}
            requiredMark={false}
          >
            <Search
              allowClear
              placeholder="Tìm bất kỳ..."
              value={valueSearch}
              onSearch={(value) => {
                setKeyword(value);
              }}
              onChange={(e) => {
                setValueSearch(e.target.value);
                if (e.target.value === '') {
                  setKeyword('');
                }
              }}
            />
            <FilterDatePicker
              label="Từ"
              value={query.startDate}
              onChange={(value) => {
                setStartDate(value);

                // dispatch(addQueryFetch({ key: 'startDate', value: value }))
              }}
            />
            <FilterDatePicker
              label="Đến"
              value={query.endDate}
              onChange={(value) => {
                setEndDate(value);
              }}
            />
          </Form>
        </div>
        <div className="page-content__right" style={{ width: '80%' }}>
          {isLoading ? (
            <SkeletonTable columns={columns} rowKey={3} />
          ) : (
            <Table
              dataSource={historiesServiceByLocation}
              columns={columns}
              size="middle"
              onChange={onTableChange}
              scroll={{ y: 'max-content' }}
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng: ${total}`,
                showSizeChanger: true
              }}
            />
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default WhServiceByLocation;
