import { Checkbox, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react'
import TableStickyTop from '~/components/Common/TableStickyTop';
import { CORE_ACTION } from '~/constants/policy';
import { findStringToSlug, useAccessResourceBranch, useDenyResourceBranch, useResourceAccessedBranch, useResourceBranch, useResourceBranchColumns } from '~/hooks';
import imgHolding from "../../../assets/images/img-holding.png";

/**
 * This component is used to render permission of branch but holding because of not yet implemented
 */
export default function Permission({ branch, isLoading,resourceType }) {
    // const [keyword, setKeyword] = useState();
    // const query = useMemo(() => ({
    //     partnerId : branch?.partnerId,
    //     resourceType
    // }), [branch]);
    // const branchId = useMemo(() => branch?._id, [branch]);

    // const [resource, loadingResource] = useResourceBranch(query);
    // const [resourceSearch,setResourceSearch] = useState();
    // const dataSource = useMemo(() => keyword ? resourceSearch : resource,[resource,resourceSearch,keyword]);
    // const [resourceAccessed, loadingResourceAccessed] = useResourceAccessedBranch(branchId);
    // const [, access] = useAccessResourceBranch();
    // const [, deny] = useDenyResourceBranch();
    // const renderPermission = (action, { key: resource }) => {
    //     const isActive = get(resourceAccessed, [resource], [])?.includes(action);
    //     const disabled = get(resourceAccessed, [resource], [])?.includes(CORE_ACTION.ADMIN.toLowerCase()) && action !== CORE_ACTION.ADMIN.toLowerCase();
    //     const onChange = (e) => {
    //         const { checked } = e.target;
    //         const submitData = {
    //             branchId,
    //             resource,
    //             action,
    //         }
    //         if (checked) {
    //             access(submitData)
    //         } else {
    //             deny(submitData)
    //         }
    //     }
    //     return (
    //         <Checkbox
    //             checked={isActive}
    //             onChange={onChange}
    //             disabled={disabled}
    //         />
    //     );
    // };
    // const columns = useResourceBranchColumns(renderPermission);
    // const onSearch = (value) => setResourceSearch(resource?.filter((item) => findStringToSlug(item?.name,value)));
    return (
        // <div>
        //     <Search
        //         style={{ width: 300 }}
        //         value={keyword}
        //         onChange={(e) => {
        //             setKeyword(e.target.value);
        //             onSearch(e.target.value)
        //         }}
        //         enterButton
        //         placeholder='Tìm chức năng'
        //         allowClear
        //     />
        //     <TableStickyTop
        //         size='small'
        //         columns={columns}
        //         dataSource={dataSource}
        //         loading={loadingResource}
        //         rowKey={rc => rc._id}
        //     />
        // </div>
        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img style={{width:'50%',height:'50%', objectFit:'contain'}} src={imgHolding} alt="" />
        </div>
    )
}
