import React, { useEffect, useCallback, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Button,
  Skeleton,
  Col,
  Select,
  InputNumber,
  DatePicker
} from 'antd';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import { removeAccents, useOptionsSelectAntd } from '~/hooks/utils';
import {
  useInitWhPartnerImport,
  useCreateWhPartnerImport,
  useWhPartnerImport,
  useResetWhPartnerImport,
  useUpdateWhPartnerImport,
  useDeleteWhPartnerImport
} from '~/hooks';
import { useCities, useDistricts, useWards } from '~/hooks/geo';
import { filterAcrossAccents } from '~/hooks/utils';
import moment from 'moment';
import POLICY from '~/constants/policy';
import { WithPermission } from '../Common';
import { KIND_WH_PARTNER_VI } from '~/constants/defaultValue';
import AddressFormSection from '~/components/Common/AddressFormSection';

const FormItem = Form.Item;
const { Option } = Select;

const filterOption = (input, option, handleClose) =>
  removeAccents(option.children.toLowerCase()).indexOf(input.toLowerCase()) >=
  0;

const WhPartnersImportForm = ({ onClose, id, handleClose }) => {
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateWhPartnerImport(onClose);
  const [, handleUpdate] = useUpdateWhPartnerImport(onClose);
  const [whPartnerImport, isLoading] = useWhPartnerImport(id);
  const initWhPartnerImport = useInitWhPartnerImport(whPartnerImport);
  useResetWhPartnerImport();

  const optionsKindWhPartner = useOptionsSelectAntd(KIND_WH_PARTNER_VI);

  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();
  
  useEffect(() => {
    form.resetFields();
  }, [initWhPartnerImport, form]);

  const onValuesChange = ({ address }) => {
    const shouldResetDistrictAndWards = address && address.city;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          district: null,
          ward: null
        }
      });
    }

    const shouldResetWards = address && address.district;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          ward: null
        }
      });
    }
  };

  useEffect(() => {
    if (!id) {
      form.resetFields();
    } else {
      // const {dateOfBirth} = initWhPartnerImport
      form.setFieldsValue(initWhPartnerImport);
      form.setFieldsValue({
        dateOfBirth: moment(initWhPartnerImport?.dateOfBirth) ?? null
      });
      form.setFieldsValue({
        name: initWhPartnerImport?.fullName
      });
      if (initWhPartnerImport) {
        const { address } = initWhPartnerImport;
      }
    }
  }, [initWhPartnerImport, id, form]);

  const onFinish = useCallback(
    (values) => {
      if (id) {
        handleUpdate({ ...values, id: id });
        handleClose();
        form.resetFields();
      } else {
        handleCreate({ ...values });
        form.resetFields();
      }
    },
    [handleCreate, handleUpdate, id]
  );

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="customer-import page-wraper hospital-form-page-content">
      <h4 style={{ margin: '20px 0 40px 20px' }}>
        {id ? ' Cập nhật' : 'Thêm mới'} đối tác
      </h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          onValuesChange={onValuesChange}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <FormItem
            label="Mã đối tác"
            name="partnerId"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
          >
            <Input disabled />
          </FormItem>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Họ và tên"
                name="fullName"
                // labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
                rules={[
                  { required: true, message: 'Xin vui lòng nhập họ và tên' }
                ]}
              >
                <Input />
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Giới tính"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn giới tính!'
                  }
                ]}
                wrapperCol={{ sm: 14, md: 14, lg: 11 }}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    <Option value="M" key="male">
                      Nam
                    </Option>
                    <Option value="F" key="female">
                      Nữ
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Số điện thoại"
                name="phoneNumber"
                // labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
                rules={[
                  !id && {
                    required: true,
                    pattern: new RegExp(/^[0-9\-\+]{10,11}$/),
                    message: 'Xin vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input maxLength={15} minLength={10} />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Email"
                name="email"
                // labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
                rules={[
                  !id && {
                    required: true,
                    pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                    message: 'Xin vui lòng nhập đúng email'
                  }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label={'Ngày sinh'}
                name={'dateOfBirth'}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn ngày sinh!'
                  }
                ]}
              >
                <DatePicker
                  // placeholder="DD/MM/YYYY"
                  allowClear
                  // format="DD-MM-YYYY"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Loại đối tác"
                name="kindPartnerPotential"
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn Loại đối tác'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select options={optionsKindWhPartner} />
                )}
              </FormItem>
            </Col>
          </Row>
            <AddressFormSection
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
            isUsingPhone={false}
            isUsingEmail = {false}
          />
          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.whPartnerImport.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}
            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default WhPartnersImportForm;
