import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select,
  Skeleton,
  Space
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from '~/components/Common';
import {
  LANGUAGE,
  MAX_PAGINATION_LIMIT,
  WH_SERVICE_TYPESHOW,
  WH_SERVICE_TYPESHOW_VI
} from '~/constants/defaultValue';
import {
  useCreateWhServiceInBranch,
  useInitWhServiceInBranch,
  useUpdateWhServiceInBranch,
  useUser,
  useWhCategories,
  useWhCategoryQueryParams,
  useWhPackageLevels,
  useWhService,
  useWhSessionOfDayQueryParams,
  useWhSessionsOfDay
} from '~/hooks';
import {
  filterAcrossAccents,
  filterActiveInstances,
  formatNumberThreeComma,
  useFormItemMargin
} from '~/hooks/utils';
import { PATH_APP } from '~/routes/paths';
import WhServicePackageLevelEdit from '../Form/WhServicePackageLevelEdit';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import { get, head } from 'lodash';
import WhServiceQuestionEdit from '../Form/WhServiceQuestionEdit';
import api from '~/api';
import TabBranch from '~/components/Common/TabBranch';
import { resetWhServiceInBranchState } from '~/redux/action';
import { toJSON } from '../parser';

const { Option } = Select;
const FormItem = Form.Item;
const WhServiceBranchForm = () => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(LANGUAGE.VI);
  const [queryCategories] = useWhCategoryQueryParams(MAX_PAGINATION_LIMIT);
  const [whCategories, isWhCategoriesLoading] =
    useWhCategories(queryCategories);
  const [whPackageLevels, isWhPackageLevelsLoading] = useWhPackageLevels();
  const [whMedicalEquipments, setWhMedicalEquipment] = useState([]);

  // const categorySelect = useMemo(() => whCategories?.find(item => get(item, '_id') === categorySelectId), [categorySelectId, categories]);

  const [branchId] = useUser();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState([]);

  const [querySessions, onPagingChange] = useWhSessionOfDayQueryParams(MAX_PAGINATION_LIMIT);
  const [whSessionsOfDay, isWhSessionsOfDayLoading] = useWhSessionsOfDay(querySessions);

  const [isSubmitLoading, handleCreate] = useCreateWhServiceInBranch();
  const [, handleUpdate] = useUpdateWhServiceInBranch();

  const { id } = useParams();
  const [whService, isGetWhServiceLoading] = useWhService(id);
  const initWhService = useInitWhServiceInBranch(whService);

  const margin = useFormItemMargin();

  const activeWhCategories = useMemo(() => {
    return filterActiveInstances(whCategories);
  }, [whCategories]);

  const activeWhSessionsOfDay = useMemo(() => {
    return filterActiveInstances(whSessionsOfDay);
  }, [whSessionsOfDay]);

  const blankQuestion = {
    title: {},
    options: [{ title: {}, image: '' }]
  };

  const blankSessionOfDay = {
    duration: null,
    extraDuration: null,
    extraPrice: null,
    extraTimeUnit: null,
    minimumQuantity: null,
    price: null,
    status: false,
    timeUnit: null
  };

  const blankPackageLevel = useMemo(() => {
    return {
      sessionPrices: activeWhSessionsOfDay.map((item, index) => ({
        ...blankSessionOfDay,
        ...(index === 0 && { status: true }),
        whSessionOfDay: { ...item },
        whSessionOfDayId: item.id
      })),
      status: true
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWhSessionsOfDay]);

  useEffect(() => {
    const fetchService = async () => {
      try {
        setLoading(true);
        const res = await api.whService.getWhServiceAvailableByBranch({
          status: 'ACTIVE',
          branchId: parseInt(branchId),
          whCategoryId: get(activeWhCategories, '_id')
        });
        setService(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    activeWhCategories && fetchService();
  }, [branchId, activeWhCategories]);

  useEffect(() => {
    form.resetFields();
  }, [initWhService, form]);

  const mergedInitWhService = useMemo(() => {
    if (!id) {
      return {
        ...initWhService,
        packages: [
          {
            ...blankPackageLevel,
            whPackageLevelId: get(head(whPackageLevels), 'id')
          }
        ]
      };
    }

    return {
      ...initWhService,
      packages: get(initWhService, 'packages')?.map((item) => {
        // find the existing session prices available in this package of the service, for example: "day" and "night"
        const existingSessionPrices = get(item, 'sessionPrices')?.map(
          (item) => ({
            ...item,
            status: get(item, 'status') === 'ACTIVE' ? true : false
          })
        );

        // there should be other session of days that were not created for this package of the service, for example: "midnight"
        // their ids should not be in the existingSessionPrices certainly
        const blankSessionPrices = activeWhSessionsOfDay
          .filter(
            (i) =>
              !existingSessionPrices.find(
                (j) => get(j, 'whSessionOfDayId') === get(i, 'id')
              )
          )
          .map((item) => ({
            ...blankSessionOfDay,
            status: false,

            whSessionOfDay: { ...item },
            whSessionOfDayId: item.id
          }));

        // return the package with both existing session prices and the remaining available session prices
        // so that user can create new on their demand
        const concatArr = existingSessionPrices.concat(blankSessionOfDay);
        let newSession = existingSessionPrices.concat(blankSessionPrices);
        let newArrSession = [];
        activeWhSessionsOfDay.forEach((e) => {
          const find = newSession?.find((ss) => ss.whSessionOfDayId === e.id);
          newArrSession.push(find);
        });
        return {
          ...item,
          status: get(item, 'status') === 'ACTIVE' ? true : false,
          sessionPrices: newArrSession
        };
      })
    };
  }, [
    id,
    initWhService,
    whPackageLevels,
    activeWhSessionsOfDay,
    blankPackageLevel
  ]);

  useEffect(() => {
    form.setFieldsValue(mergedInitWhService);
  }, [form, mergedInitWhService]);

  resetWhServiceInBranchState();

  const setWhServiceQuestionOptionImg = (image, questionIdx, optionIdx) => {
    form.setFieldsValue({
      questions: form.getFieldValue('questions').map((question, qIdx) => ({
        ...question,
        options: question.options.map((option, oIdx) => ({
          ...option,
          ...(qIdx === questionIdx && oIdx === optionIdx && { image })
        }))
      }))
    });
  };

  const setWhServicMediaImg = (image, packageIdx, mediaIdx) => {
    form.setFieldsValue({
      packages: form.getFieldValue('packages').map((_package, pIdx) => ({
        ..._package,
        media: _package.media.map((_media, mIdx) =>
          pIdx === packageIdx && mIdx === mediaIdx && image ? image : _media
        )
      }))
    });
  };

  const setWhGroupMedicalEquipment = (
    dataEquipment,
    dataMedical,
    packageIdx,
    productIndex
  ) => {
    const newDataEquipment = dataEquipment.map((item) => {
      const { _id, ...rest } = item;
      if (_id?.length === 5) {
        return rest;
      } else {
        return {
          ...rest,
          _id: _id
        };
      }
    });
    const newDataMedical = dataMedical.map((item) => {
      const { _id, ...rest } = item;
      if (_id?.length === 5) {
        return rest;
      } else {
        return {
          ...rest,
          _id: _id
        };
      }
    });
    form.setFieldsValue({
      packages: form.getFieldValue('packages')?.map((item, pkIdx) => ({
        ...item,
        groupProductInits:
          (pkIdx === packageIdx ? newDataEquipment : item?.groupProductInits) ??
          [],
        materialInits:
          (pkIdx === packageIdx ? newDataMedical : item?.materialInits) ?? []
      }))
    });
  };

  // Call API
  const onFinish = (values) => {
    const whService = toJSON({
      ...values
    });
    if (id) {
      handleUpdate({ ...whService, id });
    } else {
      handleCreate(whService);
    }
  };

  //Handle check field emty before updating or create
  const handleSubmit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
      console.log(error,'error');
      
      const { errorFields } = error;
      if (errorFields) {
        form.scrollToField(get(head(errorFields), 'name'), {
          block: 'center'
        });
        // console.log(errorFields);
        if (
          errorFields.some((field) => {
            const { name: namePath } = field;
            return namePath?.includes(
              language === LANGUAGE.VI ? LANGUAGE.EN : LANGUAGE.VI
            );
          })
        ) {
          notification.error({
            message: 'Bạn chưa nhập đủ các trường của form',
            description: `Có một số trường còn thiếu thuộc ngôn ngữ ${
              language === LANGUAGE.VI ? 'EN' : 'VI'
            }`,
            placement: 'bottom'
          });
        }
      }
    }
  };

  const onValuesChange = (changes) => {};

  const isLoading = isGetWhServiceLoading;

  return (
    <div className="wh-service-form page-wraper page-content">
        <div className="container-fluid">
          <Breadcrumb
            title={`${id ? 'Cập nhật' : 'Tạo mới'} dịch vụ`}
            routes={[
              {
                path: PATH_APP.whServiceBranch.root,
                title: 'Danh sách đăng ký dịch vụ'
              }
            ]}
          />
          <Form
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={(e) => console.log(e)}
            onValuesChange={onValuesChange}
            scrollToFirstError
            requiredMark={false}
            initialValues={mergedInitWhService}
            labelCol={{ sm: 24, md: 24, lg: 6 }}
            wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          >
            <Row justify="end">
              <Radio.Group
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <Radio.Button
                  className="wh-service-form__select-langue-btn"
                  value={LANGUAGE.VI}
                >
                  VI
                </Radio.Button>
                <Radio.Button
                  className="wh-service-form__select-langue-btn"
                  value={LANGUAGE.EN}
                >
                  EN
                </Radio.Button>
              </Radio.Group>
            </Row>

            {id && (
              <Row gutter={0} align="middle" justify="space-between">
                <Col span={12}>
                  <FormItem label="Mã dịch vụ" name={['code']}>
                    {isLoading ? <Skeleton.Input active /> : <Input readOnly />}
                  </FormItem>
                </Col>
              </Row>
            )}

            <Row gutter={0} align="middle" justify="space-between">
              <Col span={12}>
                <FormItem
                  label=""
                  name={['whCategoryId']}
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng chọn nhóm dịch vụ'
                    }
                  ]}
                >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Select
                      disabled={isWhCategoriesLoading}
                      loading={isWhCategoriesLoading}
                      showSearch
                      autoComplete="off"
                      filterOption={filterAcrossAccents}
                      placeholder="Nhóm dịch vụ"
                    >
                      {activeWhCategories.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {get(name, language)}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                {/* <FormItem label="Tham khảo dịch vụ" name={['whCategoryId']}>
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : ( */}
                    {/* <Select
                      // disabled={}
                      loading={setLoading}
                      showSearch
                      autoComplete="off"
                      filterOption={filterAcrossAccents}
                      placeholder="Dịch vụ"
                    >
                      {service.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {get(name, language)}
                        </Option>
                      ))}
                    </Select> */}
                  {/* )}
                </FormItem> */}
              </Col>
            </Row>

            <Row gutter={0} align="middle" justify="space-between">
              <Col span={12}>
                <FormItem
                  label="Tên dịch vụ"
                  name={['name', LANGUAGE.VI]}
                  className={`${language !== LANGUAGE.VI && 'hiden'}`}
                  rules={[
                    { required: true, message: 'Xin vui nhập tên dịch vụ' }
                  ]}
                >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Input.TextArea rows={4} />
                  )}
                </FormItem>
                <FormItem
                  label="Tên dịch vụ chi tiết"
                  name={['detailName', LANGUAGE.VI]}
                  className={`${language !== LANGUAGE.VI && 'hiden'}`}
                >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Input.TextArea rows={4} />
                  )}
                </FormItem>
                <FormItem
                  label="Tên dịch vụ"
                  name={['name', LANGUAGE.EN]}
                  className={`${language !== LANGUAGE.EN && 'hiden'}`}
                  rules={[
                    { required: true, message: 'Xin vui lòng nhập tên dịch vụ' }
                  ]}
                >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Input.TextArea rows={4} />
                  )}
                </FormItem>
                <FormItem
                  label="Tên dịch vụ chi tiết"
                  name={['detailName', LANGUAGE.EN]}
                  className={`${language !== LANGUAGE.EN && 'hiden'}`}
                >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Input.TextArea rows={4} />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row style={{ marginLeft: 0 }}>
              <FormItem
                label="Mô tả dịch vụ"
                name={['description', LANGUAGE.VI]}
                className={`wh-service-form__description ${
                  language !== LANGUAGE.VI && 'hiden'
                }`}
                labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input.TextArea rows={4} />
                )}
              </FormItem>

              <FormItem
                label="Mô tả dịch vụ"
                name={['description', LANGUAGE.EN]}
                className={`wh-service-form__description ${
                  language !== LANGUAGE.EN && 'hiden'
                }`}
                labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input.TextArea rows={4} />
                )}
              </FormItem>
            </Row>

            <h3 className="wh-service-form__section-title">Câu hỏi</h3>
            <Form.List name="questions">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => {
                      const props = {
                        fieldKey,
                        form,
                        index,
                        key,
                        language,
                        name,
                        remove,
                        setWhServiceQuestionOptionImg
                      };
                      return <WhServiceQuestionEdit key={key} {...props} />;
                    }
                  )}
                  {fields.length === 0 && (
                    <Row>
                      <Col>
                        <Button
                          type="dashed"
                          onClick={() => add(blankQuestion)}
                          block
                          icon={<PlusOutlined />}
                        >
                          Thêm câu hỏi
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Form.List>
            <FormItem
              style={{ margin: '20px 0' }}
              label={<h4>Kiểu hiển thị</h4>}
              name="typeShow"
            >
              <Select
                style={{ width: '150px' }}
                options={[
                  {
                    label: WH_SERVICE_TYPESHOW_VI.MINUTES,
                    value: WH_SERVICE_TYPESHOW.MINUTES
                  },
                  {
                    label: WH_SERVICE_TYPESHOW_VI.TIMES,
                    value: WH_SERVICE_TYPESHOW.TIMES
                  },
                  {
                    label: WH_SERVICE_TYPESHOW_VI.SESSION,
                    value: WH_SERVICE_TYPESHOW.SESSION
                  }
                ]}
              />
            </FormItem>
            <FormItem
              style={{ margin: '20px 0' }}
              label={<h4>Phụ thu</h4>}
              name="bonus"
            >
              <InputNumber
                style={{ width: '150px' }}
                formatter={(value) => formatNumberThreeComma(value)}
                min={0}
              />
            </FormItem>
            <h3 className="wh-service-form__section-title">Mô tả dịch vụ</h3>
            <Form.List name="packages">
              {(fields, { add, remove }) => {
                return (
                  <Space
                    direction="vertical"
                    size={64}
                    style={{ width: '100%' }}
                  >
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => {
                        const props = {
                          fieldKey,
                          form,
                          id,
                          index,
                          isLoading,
                          isWhPackageLevelsLoading,
                          isWhSessionsOfDayLoading,
                          key,
                          language,
                          name,
                          remove,
                          whPackageLevels,
                          whSessionsOfDay: activeWhSessionsOfDay,
                          setWhServicMediaImg,
                          whMedicalEquipments,
                          setWhMedicalEquipment,
                          setWhGroupMedicalEquipment,
                          initWhService
                        };
                        return (
                          <WhServicePackageLevelEdit key={key} {...props} />
                        );
                      }
                    )}
                    {fields.length < whPackageLevels.length && (
                      <Row>
                        <Col>
                          <Button
                            type="dashed"
                            onClick={() => add(blankPackageLevel)}
                            block
                            icon={<PlusOutlined />}
                          >
                            Thêm gói dịch vụ
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Space>
                );
              }}
            </Form.List>
            <Row className="wh-service-form__submit-box">
              {isSubmitLoading ? (
                <Button disabled>Huỷ</Button>
              ) : (
                <Link to={PATH_APP.whServiceBranch.root}>
                  <Button>Huỷ</Button>
                </Link>
              )}

              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isSubmitLoading}
              >
                {id ? 'Cập nhật' : 'Tạo mới'}
              </Button>
            </Row>
          </Form>
        </div>
    </div>
  );
};

export default WhServiceBranchForm;
