import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getHistories({ payload: query }) {
    try {
      const response = yield call(Api.history.getHistory, query);
      yield put({ type: Types.GET_HISTORIES_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_HISTORIES_FAILED, payload: error });
    }
};
  
function* getHistoriesServiceByLocation({ payload: query }) {
  try {
    const response = yield call(Api.history.getHistoryServiceByLocation, query);
    console.log('response tasks', response); 
    
    yield put({ type: Types.GET_HISTORIES_SERVICE_BY_LOCATION_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_HISTORIES_SERVICE_BY_LOCATION_FAILED, payload: error });
  }
};

export default function* History() {
  yield takeLatest(Types.GET_HISTORIES_REQUEST, getHistories);
  yield takeLatest(Types.GET_HISTORIES_SERVICE_BY_LOCATION_REQUEST, getHistoriesServiceByLocation);
};
