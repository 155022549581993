export const GET_HISTORIES_REQUEST = 'GET_HISTORIES_REQUEST';
export const GET_HISTORIES_SUCCESS = 'GET_HISTORIES_SUCCESS';
export const GET_HISTORIES_FAILED = 'GET_HISTORIES_FAILED';

export const GET_HISTORIES_SERVICE_BY_LOCATION_REQUEST = 'GET_HISTORIES_SERVICE_BY_LOCATION_REQUEST';
export const GET_HISTORIES_SERVICE_BY_LOCATION_SUCCESS = 'GET_HISTORIES_SERVICE_BY_LOCATION_SUCCESS';
export const GET_HISTORIES_SERVICE_BY_LOCATION_FAILED = 'GET_HISTORIES_SERVICE_BY_LOCATION_FAILED';

export const RESET_STORE = 'RESET_STORE';
export const RESET_HISTORY_STATE = 'RESET_HISTORY_STATE';
