export const GET_BRANCHES_REQUEST = 'GET_BRANCHES_REQUEST';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILED = 'GET_BRANCHES_FAILED';

export const GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_FAILED = 'GET_BRANCH_FAILED';

export const CREATE_BRANCH_REQUEST = 'CREATE_BRANCH_REQUEST';
export const CREATE_BRANCH_SUCCESS = 'CREATE_BRANCH_SUCCESS';
export const CREATE_BRANCH_FAILED = 'CREATE_BRANCH_FAILED';

export const UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAILED = 'UPDATE_BRANCH_FAILED';

export const DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILED = 'DELETE_BRANCH_FAILED';

export const GET_RESOURCE_BRANCH_REQUEST = 'GET_RESOURCE_BRANCH_REQUEST';
export const GET_RESOURCE_BRANCH_SUCCESS = 'GET_RESOURCE_BRANCH_SUCCESS';
export const GET_RESOURCE_BRANCH_FAILED = 'GET_RESOURCE_BRANCH_FAILED';

export const GET_RESOURCE_ACCESSED_BRANCH_REQUEST = 'GET_RESOURCE_ACCESSED_BRANCH_REQUEST';
export const GET_RESOURCE_ACCESSED_BRANCH_SUCCESS = 'GET_RESOURCE_ACCESSED_BRANCH_SUCCESS';
export const GET_RESOURCE_ACCESSED_BRANCH_FAILED = 'GET_RESOURCE_ACCESSED_BRANCH_FAILED';

export const ACCESS_RESOURCE_BRANCH_REQUEST = 'ACCESS_RESOURCE_BRANCH_REQUEST';
export const ACCESS_RESOURCE_BRANCH_SUCCESS = 'ACCESS_RESOURCE_BRANCH_SUCCESS';
export const ACCESS_RESOURCE_BRANCH_FAILED = 'ACCESS_RESOURCE_BRANCH_FAILED';

export const DENY_RESOURCE_BRANCH_REQUEST = 'DENY_RESOURCE_BRANCH_REQUEST';
export const DENY_RESOURCE_BRANCH_SUCCESS = 'DENY_RESOURCE_BRANCH_SUCCESS';
export const DENY_RESOURCE_BRANCH_FAILED = 'DENY_RESOURCE_BRANCH_FAILED';

export const ASSIGN_CATEGORY_BRANCH_REQUEST = 'ASSIGN_CATEGORY_BRANCH_REQUEST';
export const ASSIGN_CATEGORY_BRANCH_SUCCESS = 'ASSIGN_CATEGORY_BRANCH_SUCCESS';
export const ASSIGN_CATEGORY_BRANCH_FAILED = 'ASSIGN_CATEGORY_BRANCH_FAILED';

export const REMOVE_CATEGORY_BRANCH_REQUEST = 'REMOVE_CATEGORY_BRANCH_REQUEST';
export const REMOVE_CATEGORY_BRANCH_SUCCESS = 'REMOVE_CATEGORY_BRANCH_SUCCESS';
export const REMOVE_CATEGORY_BRANCH_FAILED = 'REMOVE_CATEGORY_BRANCH_FAILED';

export const RESET_BRANCH_STATE = 'RESET_BRANCH_STATE';
