import React, { useMemo } from 'react';
import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
  useParams
} from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { PATH_APP } from '~/routes/paths';
import { NavItem, Nav } from 'reactstrap';
import Info from './Info';
import UserEmployee from '~/components/UserEmployee/Users';
import EmployeeGroups from '~/components/UserEmployee/Groups';
import { useBranch } from '~/hooks';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import PermissionGate from '~/routes/middleware/PermissionGate';
import Permission from './Permission';
import CategoryBranch from './CategoryBranch';
import GroupCategoryRoutes from '~/components/UserEmployee/GroupCategory';

const Branch = () => {
  const { path, url } = useRouteMatch();
  const resourceType = useMemo(() => {
    if(path?.includes("BRANCH")) return "BRANCH";
    if(path?.includes("CLINIC")) return "CLINIC";
  },[path])
  const {
    location: { pathname }
  } = useHistory();
  const { id } = useParams();
  const [branch, isLoading] = useBranch(id);

  const className = (path) => {
    return `nav-link ${pathname.split('/').includes(path) && 'active'}`;
  };

  return (
    <div className="branch-detail page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title="Thông tin chi nhánh"
          routes={[
            { path: PATH_APP.branch.root, title: 'Danh sách chi nhánh' }
          ]}
        />
        <Nav
          tabs
          className="nav-tabs-custom nav-justified mb-4"
          style={{
            width: 1000,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: -16
          }}
        >
          <NavItem>
            <Link to={`${url}/info`} className={className('info')}>
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Thông tin chi tiết</span>
            </Link>
          </NavItem>

          <NavItem>
            <Link to={`${url}/permission`} className={className('permission')}>
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Phân quyền</span>
            </Link>
          </NavItem>

          <NavItem>
            <WithPermission permission={POLICY.READ_USER}>
              <Link to={`${url}/user`} className={className('user')}>
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Người dùng</span>
              </Link>
            </WithPermission>
          </NavItem>

          <WithPermission permission={POLICY.READ_USERGROUP}>
            <NavItem>
              <Link
                to={`${url}/user-group`}
                className={className('user-group')}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Nhóm người dùng</span>
              </Link>
            </NavItem>
          </WithPermission>

          <WithPermission permission={POLICY.READ_USERGROUPSERVICE}>
            <NavItem>
              <Link
                to={`${url}/category-branch`}
                className={className('category-branch')}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Phân bổ nhóm dịch vụ</span>
              </Link>
            </NavItem>
          </WithPermission>

          <WithPermission permission={POLICY.READ_USERGROUPSERVICE}>
            <NavItem>
              <Link
                to={`${url}/groupCategory`}
                className={className('groupCategory')}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Nhóm dịch vụ</span>
              </Link>
            </NavItem>
          </WithPermission>

        </Nav>

        <Switch>
          <Route
            path={`${path}/info`}
            component={() => <Info branch={branch} isLoading={isLoading} />}
          />

          <PermissionGate
            path={`${path}/permission`}
            component={() => <Permission resourceType={resourceType} branch={branch} isLoading={isLoading}/>}
            // permission={POLICY.READ_USER}
          />

          <PermissionGate
            path={`${path}/user`}
            component={UserEmployee}
            permission={POLICY.READ_USER}
          />

          <PermissionGate
            path={`${path}/user-group`}
            component={EmployeeGroups}
            permission={POLICY.READ_USERGROUP}
          />

          <PermissionGate
            path={`${path}/category-branch`}
            component={CategoryBranch}
            permission={POLICY.READ_USERGROUPSERVICE}
          />

          <PermissionGate
            path={`${path}/groupCategory`}
            component={GroupCategoryRoutes}
            permission={POLICY.READ_USERGROUPSERVICE}
          />

          <Redirect to={`${path}/info`}></Redirect>
        </Switch>
      </div>
    </div>
  );
};

export default Branch;
