import { Checkbox, Table, Tooltip } from 'antd';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BoxShadow from '~/components/Common/BoxShadow';
import SearchInputCustom from '~/components/Common/SearchInputCustom';
import { useAssignCategoryBranch, useBranch, useRemoveCategoryBranch } from '~/hooks';
import { useCategoryAll } from '~/hooks/reportSystem';
import { filterSlug, Permissions } from '~/hooks/utils';

export default function CategoryBranch() {
  const { canUpdate } = Permissions('USERGROUPSERVICE');

  const { id } = useParams();
  const [branch, isLoading] = useBranch(id);
  const [kw, setKw] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [category, categoryLoading] = useCategoryAll(true);
  const [,inAssign] = useAssignCategoryBranch();
  const [,onRemove] = useRemoveCategoryBranch();
  const columns = [
    {
      title: "Tên nhóm dịch vụ",
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: "Thao tác",
      dataIndex: '_id',
      key: '_id',
      width: 80,
      align: 'center',
      render: (_id) => {
        const isChecked = get(branch,'whCategoryBranch',[])?.some((item) => item?.whCategoryId === _id);
        const payload = {
          branchId : branch?._id,
          whCategoryId : _id
        };

        return <Tooltip title={!canUpdate && "Bạn không có quyền thực hiện"}>
          <Checkbox disabled={!canUpdate} onChange={(e) => e.target.checked ? inAssign(payload) : onRemove(payload)} checked={isChecked}/>
        </Tooltip>
      }
    },

  ];
  useEffect(() => {
    let data = [];
    if (kw) {
      const filterData = category?.filter(
        (item) =>
          filterSlug(kw, item?.name || "")
      );
      data = filterData;
    } else {
      data = category;
    };
    setDataSource(data);
  }, [kw, category]);

  return (
    <div>
      <BoxShadow style={{ padding: 20, borderRadius: 10 }}>
        <SearchInputCustom value={kw} onChange={(k) => setKw(k)} style={{ width: 300, marginBottom: 10 }} placeholder='Tìm tên nhóm dịch vụ' />
        <Table loading={categoryLoading || isLoading} dataSource={dataSource} columns={columns} size="small" />
      </BoxShadow>
    </div>
  )
}
