import React, { Fragment, createRef, useEffect, useMemo, useState } from 'react';

import { Button, Card, Col, Modal, Row, Space, Table, Tag } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import { PlusOutlined, DeleteOutlined, SnippetsFilled } from '@ant-design/icons';
import { concat, countBy, flatten, get, groupBy, intersectionWith, last, omit, set, sumBy } from 'lodash';

import api from '~/api';
import { Breadcrumb, WithOrPermission } from '~/components/Common';
import {
  ADDITIONAL_VOUCHER,
  COMPONENT_MODES,
  WH_APPOINTMENT_GIVE_AWAY,
  MAP_WH_BILL_ITEM_STATUS_TAG,
  MAX_PAGINATION_LIMIT,
  MODE_VOUCHER_WAREHOUSE,
  PAYMENT_METHOD,
  TYPE_VOUCHER_WAREHOUSE,
  TYPE_VOUCHER_WAREHOUSE_VI,
  UPDATABLE_WH_APPOINTMENT_STATUSES,
  WH_APPOINTMENT_STATUS,
  WH_BILL_ITEM_STATUS,
  WH_VOUCHER_STATUS
} from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { HealthDeclaration, WhBillItemNote, WithPermission } from '~/components/Common';
import POLICY, { CORE_ACTION, GROUP_POLICY } from '~/constants/policy';

import {
  calculateTotalValueDepreciation,
  useWhAppointments,
  // useAddWhAppointmentsLocally,
  // useRemoveWhAppointmentsLocally,
} from '~/hooks/whAppointment';
import { useWhBill } from '~/hooks/whBill';
import {
  useCustomerCancelWhBillItem,
  useCustomerCancelWhBillItemSuccessSelector,
  useCustomerCompletedWhBillItem,
  useResetCreateCancel,
  useResetWhBillItem,
  useUpdateWhAppointments,
  useUpdateWhAppointmentsSuccessSelector,
  useWhBillItem,
} from '~/hooks/whBillItem';
import { useWhPackageLevels } from '~/hooks/whPackageLevel';
import { useWhService } from '~/hooks/whService';
import { useWhSessionsOfDay, useWhSessionOfDayQueryParams } from '~/hooks/whSessionOfDay';

import { floorFormatter, notifyHttpError } from '~/utils/helper';

import WhAppointmentsByBlockTable from '../WhAppointment/WhAppointmentsByBlockTable/index';
import WhBillItemCategoryInfo from './WhBillItemCategoryInfo';
import WhBillItemServiceInfo from './WhBillItemServiceInfo';
import HistoryLogs from '../WhBill/HistoryLog';
import WhPaymentVoucherForm from '../WhVoucher/Form/WhPaymentVoucherForm';
import WhReceiptVoucherForm from '../WhVoucher/Form/WhReceiptVoucherForm';
import ModalConfirmCancel from './ModalConfirmCancel';
import WhBillItemNoteCancel from '../WhBill/WhBillItemNoteCancel';
import ModalConfirmCompleted from './ModalComfirmCompleted';
import WhBillItemNoteCompleted from '../WhBill/WhBillItemNoteCompleted';
const WhBillItemUpdateAppointments = ({

}) => {
  // useResetWhBillItem();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [blockRefs, setBlockRefs] = useState({});
  
  const [isOpenConfirmAddBlockModal, setIsOpenConfirmAddBlockModal] = useState(false);
  const [isOpenConfirmRemoveBlockModal, setIsOpenConfirmRemoveBlockModal] = useState(false);
  const [isOpenCustomerCancelWhBillItemModal, setIsOpenCustomerCancelWhBillItemModal] = useState(false);

  // const [addWhAppointmentsLocally] = useAddWhAppointmentsLocally();
  // const [removeWhAppointmentsLocally] = useRemoveWhAppointmentsLocally();
  const history = useHistory();

  const { id } = useParams();
  const [whBillItem, isGetWhBillItemLoading] = useWhBillItem(id);
  const [whBillItemData,setWhBillItemData] = useState([])
  const [whBill, isGetWhBillLoading] = useWhBill(get(whBillItem, "whBillId"));
  let historyLogs = useMemo(() => {
    let logsWhAppointments = []
    get(whBillItem, 'whAppointments',[])?.forEach(whAppointment => get(whAppointment, "historyLogs",[])?.forEach(f => logsWhAppointments.push(f)))
    return concat(get(whBillItem,'historyLogs',[]),logsWhAppointments)
  },[whBillItem])
  const [query, setQuery] = useState({
    whBillItemId: id,
    limit: MAX_PAGINATION_LIMIT,
    page: 1,
    populate: true,
    deletedAt: null, // does not work at the moment,
    reFetch: false
  });
  
  const [whAppointmentsFeatch, isWhAppointmentsLoading] = useWhAppointments(query);
  const [whAppointments, setWhAppointments] = useState([]);
  const [whService, isGetWhServiceLoading] = useWhService(get(whBillItem, "whServiceId"));
  const [whPackageLevels, isWhPackageLevelsLoading] = useWhPackageLevels();
  const [querySessions,] = useWhSessionOfDayQueryParams(MAX_PAGINATION_LIMIT);
  const [whSessionsOfDay, isWhSessionsOfDayLoading] = useWhSessionsOfDay(querySessions);
  const mutateAppointment = () => setQuery({...query,reFetch : !query.reFetch}) // trigger re-fetch
  const [isUpdateWhAppointmentsLoading, handleUpdateWhAppointments] = useUpdateWhAppointments(mutateAppointment);
  const [isCustomerCancelWhBillItemLoading, handleCustomerCancelWhBillItem] = useCustomerCancelWhBillItem();
  const [isCustomerCompletedWhBillItemLoading, handleCustomerCompletedWhBillItem] = useCustomerCompletedWhBillItem(); // [isCustomerCompletedWhBillItemLoading]
  const [infoRating,setInfoRating] = useState(null)
  const [isOpenPaymentVoucherModal, setIsOpenPaymentVoucherModal] = useState(false);
  const [isOpenReceiptVouchersModal, setIsOpenReceiptVoucherModal] = useState(false);
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState(false);
  const [isShowButtonCancel, setIsShowButtonCancel] = useState(false);
  const [totalMoneyCancel, setTotalMoneyCancel] = useState(0);
  const [note, setNote] = useState('');
  const [noteCompleted,setNoteCompleted]=useState('')
  const [noteShow, setNoteShow] = useState('');
  const [noteCompleteShow,setNoteCompletedShow] = useState('');
  const [isOpenConfirmCompeleted, setShowFormUpdateStatus] = useState(false);
  const onOpenPaymentVouchersModal = () => {
    setIsOpenPaymentVoucherModal(true)
  }
  const onClosePaymentVouchersModal = () => {
    setIsOpenPaymentVoucherModal(false)
  }
  const onOpenReceiptVouchersModal = () => {
    setIsOpenReceiptVoucherModal(true)
  }
  const onCloseReceiptVouchersModal = () => {
    setIsOpenReceiptVoucherModal(false)
  }
  const onOpenConfirmCancel = () => {
    setIsOpenConfirmCancel(true);
  };
  const onCloseConfirmCancel = () => {
    setIsOpenConfirmCancel(false);
  };
  const onCloseConfirmCompeleted = () => {
    setShowFormUpdateStatus(false)
    setNoteCompleted('')
  }
  useEffect(() => {
    async function fetchData() {
      const res = await api.evaluate.getAllRating()
      setInfoRating(res.docs)
    }
    fetchData();
  }, []);
  useEffect(() => {
    setWhBillItemData(whBillItem)
  },[whBillItem])
  useEffect(() => {
    whAppointmentsFeatch?.length ? setWhAppointments(whAppointmentsFeatch) : setWhAppointments([]);
  }, [whAppointmentsFeatch])
  
  const customerCancelWhBillItemSuccess = useCustomerCancelWhBillItemSuccessSelector();

  const [computedWhBillItem, setComputedWhBillItem] = useState({
    grandTotal: null,
    grandTotalInProgress: null,
    remainingAmount: null,
    totalAmountPaid: null,
    actualCost: null,
    totalDepreciation : null
  });
  console.log(whAppointments,'whAppointments')
  useEffect(() => {
    // const sumExtraPrice = sumBy(whAppointments,"extraPrice");
    // const sumExtraPrice = whAppointments?.reduce((sum,whAppointment) => sum + (get(whAppointment,'extraPrice',1) * get(whAppointment,'extraQuantity',0)),0)
    setComputedWhBillItem({
      ...computedWhBillItem,
      actualCost: sumBy(whAppointments, "actualCost"),
      grandTotal: sumBy(whAppointments, "grandTotal"),
      totalDepreciation : sumBy(whAppointments,(e) => calculateTotalValueDepreciation(get(e,'listProduct',[])))
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whAppointments]);

  const mergedWhAppointments = useMemo(() => {
    return whAppointments
      .filter(item => !get(item, "deletedAt"))
      .map(item => ({
        ...item,
        whPackageLevel: whPackageLevels?.find(wPL => item.whPackageLevelId === wPL._id),
        whSessionOfDay: whSessionsOfDay?.find(wSD => item.whSessionOfDayId === wSD._id),
      }))
  }, [
    whAppointments,
    whPackageLevels,
    whSessionsOfDay
  ]);
  
  const blocks = useMemo(() => {
    const grouped = groupBy(mergedWhAppointments, item => {
      if (item.Type) {
          return 'GIVE_AWAY';
      } else {
          return item.blockNumber;
      }
    });
    return grouped;
  }, [mergedWhAppointments]);
  // when the length of blocks changes (after fetching whAppointments, or user add new block)
  // re-create refs for each block and pass them to WhAppointmentsByBlockTable
  // so we can get data from them later using getDataFromBlocks.
  useEffect(() => {
    const newBlockRefs = {};
    Object.keys(blocks).forEach((blockNumber, index) => {
      newBlockRefs[blockNumber] = createRef();
    });
    setBlockRefs(newBlockRefs);
  }, [Object.keys(blocks).length]);

  useEffect(() => {
    if (!isCustomerCancelWhBillItemLoading && customerCancelWhBillItemSuccess) {
      setIsOpenCustomerCancelWhBillItemModal(false);
    }
  }, [isCustomerCancelWhBillItemLoading, customerCancelWhBillItemSuccess]);

  // Check button cancel display conditions
  useEffect(() => {
    const isCheck = whBillItem?.whAppointments?.filter((item) => item.status === 'NEW')
    isCheck?.length ? setIsShowButtonCancel(true) : setIsShowButtonCancel(false);
  },[whBillItem]);

  useEffect(() => {
    setNote('');
    setNoteShow('')
  }, [id])
  // this is not used anymore since adding a new block action must call API
  /* const addNewBlockLocally = () => {
    const keys = Object.keys(blocks);
    const lastKey = last(keys);
    const lastBlockRef = blockRefs[lastKey];

    // const lastBlockData = blocks[lastKey];
    const lastBlockData = lastBlockRef.current.getBlockData();

    const clonedWhAppointments = lastBlockData.map((item, index) => {
      return {
        ...omit(item, [ "code", "status", "checkIn", "checkOut"]),
        blockNumber: parseInt(lastKey) + 1,
      };
    });
    addWhAppointmentsLocally(clonedWhAppointments);
  } */

  const addNewBlock = async () => {
    try {
      const response = await api.whBillItem.createBlock(whBillItem);
      return response
    } catch (error) {
      notifyHttpError(error);
      throw error;
    }
  }

  const onConfirmAddNewBlock = async () => {
    try {
      await onSubmit();
      const response = await addNewBlock();
      setQuery({ ...query, whBillItemId: get(response, "id") || get(response, "_id") }); // to trigger query whAppointments using useWhAppointments(query)
      setIsOpenConfirmAddBlockModal(false);
    } catch (error) {
      console.error(error);
    }
  }

  // this is not used anymore since removing a block action must call API
  /* const removeBlockLocally = (blockNumber, index) => {
    removeWhAppointmentsLocally(item => parseInt(get(item, "blockNumber")) === parseInt(blockNumber));
  } */

  const removeBlock = async (blockNumber, index) => {
    try {
      const response = await api.whBillItem.deleteBlock({ whBillItem, blockNumber });
      return response;
    } catch (error) {
      notifyHttpError(error);
      throw error;
    }
  }

  const onConfirmRemoveBlock = async (givenBlockNumber, givenIndex) => {
    // if there is no given block number or index, remove the last block
    let blockNumber = givenBlockNumber;
    let index = givenIndex;
    if (!blockNumber || !index) {
      blockNumber = last(Object.keys(blocks))
      index = Object.keys(blocks).length - 1
    }
    try {
      await onSubmit();
      const response = await removeBlock(blockNumber, index);
      setQuery({ ...query, whBillItemId: get(response, "id") || get(response, "_id") }) // to trigger query whAppointments using useWhAppointments(query)
      setIsOpenConfirmRemoveBlockModal(false);
    } catch (error) {
      console.error(error);
    }
  }

  const onWhAppointmentActualCostChange = value => {
    const currentWhAppointments = getDataFromBlocks();
    const {
      remainingAmount,
      grandTotal,
      grandTotalInProgress,
      totalAmountPaid,
      actualCost,
    } = calculateWhBillItemRemainingAmount(currentWhAppointments);

    setComputedWhBillItem({
      remainingAmount,
      grandTotal,
      grandTotalInProgress,
      totalAmountPaid,
      actualCost,
    })
  }

  const calculateWhBillItemRemainingAmount = currentWhAppointments => {
    const newWhBillItemGrandTotal = sumBy(currentWhAppointments, "grandTotal");
    const newWhBillItemGrandTotalCompleted = sumBy(currentWhAppointments, (appointment) =>
      appointment.status === WH_APPOINTMENT_STATUS.COMPLETED
        ? appointment.grandTotal
        : 0
    );
    const newWhBillItemGrandTotalInProgress = newWhBillItemGrandTotal - newWhBillItemGrandTotalCompleted;

    const whBillGrandTotal = newWhBillItemGrandTotal + sumBy(whBill.billItems, item => {
      if (get(item, "_id") !== get(whBillItem, "_id")) return get(item, "grandTotal");
      return 0;
    });
    const newWhBillItemRatio = newWhBillItemGrandTotal / whBillGrandTotal;
    const whBillItemActualCost = (whBillGrandTotal - get(whBill, "actualPrepaymentAmount") - get(whBill, "discountAmount") - get(whBill, "discountedRates") - get(whBill, "refund")) * newWhBillItemRatio;
    const whBillItemTotalAmountPaid = sumBy(currentWhAppointments, item => {
      if (get(item, "status") === WH_APPOINTMENT_STATUS.COMPLETED) return get(item, "actualCost");
      return 0;
    })
    const whBillItemRemainingAmount = whBillItemActualCost - whBillItemTotalAmountPaid;

    return {
      grandTotal: newWhBillItemGrandTotal,
      grandTotalInProgress: newWhBillItemGrandTotalInProgress,
      remainingAmount: whBillItemRemainingAmount,
      totalAmountPaid: whBillItemTotalAmountPaid,
      actualCost: whBillItemActualCost,
    }
  }

  const getDataFromBlocks = () => {
    const dataOfBlocks = Object.entries(blockRefs).map(([blockNumber, blockRef]) => {
      return blockRef.current.getBlockData();
    })
    return flatten([...dataOfBlocks]);
  };
  const onSubmit = () => {
    // setIsSubmitLoading(true);
    // try {
    //   const response = await api.whBillItem.updateWhAppointments({
    //     whBillItem,
    //     whAppointments: getDataFromBlocks(),
    //   })
    //   toastr.success("Cập nhật phân bổ lịch hẹn thành công");
    // } catch (error) {
    //   toastr.error("Cập nhật phân bổ lịch hẹn thất bại");
    //   console.error(error);
    // } finally {
    //   setIsSubmitLoading(false);
    // }
    const newWhAppointments = getDataFromBlocks()?.map(e => ({...e,whPartnerId : !e.whPartnerId ? null : e.whPartnerId,whPartner : !e.whPartner ? null : e.whPartner}))
    const submitData = {
      whBillItem ,
      whAppointments: newWhAppointments
    }
    handleUpdateWhAppointments(submitData);

    
  }
  useResetWhBillItem();
  const onCustomerCancel = () => {
    handleCustomerCancelWhBillItem(whBillItem);

    if (customerCancelWhBillItemSuccess) {
      setIsOpenCustomerCancelWhBillItemModal(false);
    }
  }

  const renderInfoGiveAwayAppointment = (index) => {
    const service = blocks[index]?.[0]?.snapshotService?.name?.vi ?? '';
    const code = blocks[index]?.[0]?.snapshotService?.code ?? '';
    const category = blocks[index]?.[0]?.snapshotService?.categoryId?.name?.vi ?? '';
    return (
      <div>
        <span> 
        {`Tặng buổi hẹn - `}
        </span>
        <span style={{fontSize: '18px'}}> 
          {`Nhóm: ${category} - Mã DV: ${code} - Dịch vụ: ${service}`}
        </span>
      </div>
    );
  };

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title={`Lịch hẹn ${get(whBill, "billNumber")} - ${get(whBill, "customerAddress.fullName")}`} />

        <Row justify="space-between">
          <Col><WhBillItemServiceInfo whBillItem={whBillItem} whBill={whBill} /></Col>
        <Col>  <WhBillItemCategoryInfo isLoading={isGetWhBillItemLoading && isGetWhBillItemLoading} whBillItem={whBillItem} whCategory={get(whService, "whCategory")} /></Col>
        </Row>

        <Card
          title="PHÂN BỔ LỊCH HẸN"
          extra={
          <Row gutter={16} align='middle'>
            {
              (whBillItem?.status !== WH_BILL_ITEM_STATUS.COMPLETED) ?  <Col>
              <Button onClick={()=> setShowFormUpdateStatus(true)}>
                Cập nhật trạng thái đơn hàng
              </Button>
              </Col> : null
            }
         
          <WithOrPermission permission={GROUP_POLICY(CORE_ACTION.WRITE)?.MANAGE_VOUCHER_IN_WHBILL}>
          {(whBill?.prePayAmount > 0 && ( whBill.status === WH_BILL_ITEM_STATUS.CUSTOMER_CANCEL || whBill.status === WH_BILL_ITEM_STATUS.COMPLETED)) ? <Col>
          <Button onClick={onOpenPaymentVouchersModal}>
            Tạo phiếu chi
            </Button>
          </Col> : null}
          <Col>
          <Button onClick={onOpenReceiptVouchersModal}>
            Tạo phiếu thu
            </Button>
          </Col>
          </WithOrPermission>
          
            <Col>
            <Tag color={get(MAP_WH_BILL_ITEM_STATUS_TAG, `${get(whBillItemData, 'status')}.color`)}>
              {get(MAP_WH_BILL_ITEM_STATUS_TAG, `${get(whBillItemData, 'status')}.name`)}
            </Tag>
            </Col>
          </Row>
            // <Select
            //   value={get(whBillItem, "status")}
            //   style={{ width: "100% ", minWidth: "180px" }}
            // >
            //   {Object.entries(WH_BILL_ITEM_STATUS).map(([key, value]) => (
            //     <Select.Option key={key} value={key}>{MAP_WH_BILL_ITEM_STATUS_TAG[value].name}</Select.Option>
            //   ))}
            // </Select>
          }
        >
          <Space direction='vertical' size="large" style={{ width: "100%" }}>
            {Object.keys(blocks).map((blockNumber, index) => {
              return(
              <Fragment key={index}>
                <Row justify='space-between'>
                  <h3>{blockNumber === WH_APPOINTMENT_GIVE_AWAY ? renderInfoGiveAwayAppointment(blockNumber): `Block ${blockNumber}`}</h3>
                  <Space>
                    {(index === Object.keys(blocks).length - 1) && (
                      <WithPermission permission={POLICY.WRITE_WHAPPOINTMENT}>
                        <Button
                          // onClick={addNewBlockLocally}
                          onClick={() => setIsOpenConfirmAddBlockModal(true)}
                          icon={<PlusOutlined />}
                          type="primary"
                        >
                          Thêm block
                        </Button>
                      </WithPermission>
                    )}
                    {(index !== 0 && index === Object.keys(blocks).length - 1) && (
                      <WithPermission permission={POLICY.DELETE_WHAPPOINTMENT}>
                        <Button
                          // onClick={() => removeBlockLocally(blockNumber, index)}
                          onClick={() => setIsOpenConfirmRemoveBlockModal(true)}
                          icon={<DeleteOutlined />}
                          danger
                        >
                          Xóa block
                        </Button>
                      </WithPermission>
                    )}
                  </Space>
                </Row>
                {
                  (
                    isGetWhBillItemLoading ||
                    isGetWhBillLoading ||
                    // isWhAppointmentsLoading ||  // do not use isWhAppointmentsLoading here because in case of adding/removing block, we don't want to show loading table then
                    isGetWhServiceLoading ||
                    isWhPackageLevelsLoading ||
                    isWhSessionsOfDayLoading
                  ) ? <Table loading />
                    : <WhAppointmentsByBlockTable
                        infoRating={infoRating}
                        onUpdate={onSubmit}
                        mode={COMPONENT_MODES.EDIT}
                        dataSource={blocks[blockNumber]}
                        ref={blockRefs[blockNumber]}
                        onWhAppointmentActualCostChange={onWhAppointmentActualCostChange}
                        // filter the packages available in this service only
                        optionWhPackageLevels={intersectionWith(
                          whPackageLevels,
                          get(whService, "packages"),
                          (i, j) => get(i, "_id") === get(j, "whPackageLevelId")
                        ).map(item => ({
                          label: get(item, "name.vi"),
                          value: get(item, "_id") || get(item, "id")
                        }))}
                        computedWhBillItem={computedWhBillItem}
                        whService={whService}
                        whBill={whBill}
                        mutateAppointment={mutateAppointment}
                        whBillItem={whBillItem}
                        isWhAppointmentsLoading={isWhAppointmentsLoading}
                        mergedWhAppointments={mergedWhAppointments}
                      />
                }
              </Fragment>
            )})}
          </Space>

          <Row>
            <Col span={6} offset={12}>
                <h5 style={{ textAlign: 'right' }}>Tổng khấu hao: {floorFormatter(get(computedWhBillItem,'totalDepreciation',0))} </h5>
                <h5 style={{ textAlign: 'right' }}>Tổng tạm tính</h5>
                <h5 style={{ textAlign: 'right' }}>của dịch vụ</h5>
                <h5 style={{ textAlign: 'right' }}>{floorFormatter(computedWhBillItem.grandTotal)}</h5>
            </Col>
            <Col span={3}>
                <h5 style={{ textAlign: 'right' }}>Tổng phải thu</h5>
                <h5 style={{ textAlign: 'right' }}>của dịch vụ</h5>
                <h5 style={{ textAlign: 'right' }}>{floorFormatter(computedWhBillItem.actualCost)}</h5>
            </Col>
          </Row>
        </Card>

        <Row style={{ marginTop: "16px" }}>
          <Col span={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SnippetsFilled color='#3481FF' style={{ fontSize: '24px', color: '#3481FF', marginRight: '8px' }} />
          </Col>
          <Col span={5}>
            <HealthDeclaration
              whBillItem={whBillItem}
              whService={whService}
            />
          </Col>
          <Col span={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SnippetsFilled color='#3481FF' style={{ fontSize: '24px', color: '#3481FF', marginRight: '8px' }} />
          </Col>
          <Col span={5}>
            <WhBillItemNote
              whBillItem={whBillItem}
            />
          </Col>
          <Col span={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SnippetsFilled color='#3481FF' style={{ fontSize: '24px', color: '#ED2B2A', marginRight: '8px' }} />
          </Col>
          <Col span={5}>
            <WhBillItemNoteCancel
              whBillItem={whBillItem}
              note={note}
              setNote={setNote}
              id={id}
              noteShow={noteShow}
              setNoteShow = {setNoteShow}
            />
          </Col>
          <Col span={5}>
            <WhBillItemNoteCompleted
              whBillItem={whBillItem}
              noteCompleted={noteCompleted}
              setNoteCompleted={setNoteCompleted}
              id={id}
              noteCompleteShow={noteCompleteShow}
              setNoteCompletedShow = {setNoteCompletedShow}
            />
          </Col>
        </Row>

        <Row className="wh-service-form__submit-box">
          {
            isShowButtonCancel ?
              <WithPermission permission={POLICY.UPDATE_WHAPPOINTMENT}>
                  <Button loading={!!isUpdateWhAppointmentsLoading} onClick={() => onOpenConfirmCancel()}>Huỷ</Button>
              </WithPermission>
              : null
          }
          <WithPermission permission={POLICY.UPDATE_WHAPPOINTMENT}>
            <Button
              // htmlType="submit"
              loading={!!isUpdateWhAppointmentsLoading}
              onClick={onSubmit}
              type="primary"
            >
              {'Cập nhật'}
            </Button>
          </WithPermission>
          {get(whBill, "paymentMethod") === PAYMENT_METHOD.COD && get(whBillItem, "status") === WH_BILL_ITEM_STATUS.COMPLETED && (
            <WithPermission permission={POLICY.UPDATE_WHUPDATEBILLSTATUS}>
              <Button
                // htmlType="submit"
                loading={!!isCustomerCancelWhBillItemLoading}
                onClick={() => setIsOpenCustomerCancelWhBillItemModal(true)}
                danger
              >
                {'Chấm dứt'}
              </Button>
            </WithPermission>
          )}
        </Row>

        <Modal
          title={"Thêm block mới"}
          visible={isOpenConfirmAddBlockModal}
          onOk={onConfirmAddNewBlock}
          onCancel={() => setIsOpenConfirmAddBlockModal(false)}
        >
          Bạn có muốn lưu lại các thay đổi đã tạo trước khi thêm block?
        </Modal>

        <Modal
          title={"Xóa block"}
          visible={isOpenConfirmRemoveBlockModal}
          onOk={onConfirmRemoveBlock}
          onCancel={() => setIsOpenConfirmRemoveBlockModal(false)}
        >
          Bạn có muốn lưu lại các thay đổi đã tạo trước khi xóa block này?
        </Modal>

        <Modal
          title={"Chấm dứt"}
          visible={isOpenCustomerCancelWhBillItemModal}
          onOk={onCustomerCancel}
          onCancel={() => setIsOpenCustomerCancelWhBillItemModal(false)}
        >
          Bạn có chắc chắn muốn chấm dứt dịch vụ này?
        </Modal>
      </div>
      
      <HistoryLogs historyLogs={historyLogs}></HistoryLogs>
      
        <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onClosePaymentVouchersModal}
        onOk={() => { }}
        title={`Phiếu chi - Đơn hàng ${get(whBill, "billNumber")} - ${get(whBill, 'customerAddress.fullName')}`}
        width={1500}
        visible={isOpenPaymentVoucherModal}
      >
        <WhPaymentVoucherForm
          whBill={whBill}
          whBillItem={whBillItem}
          onCancel={onClosePaymentVouchersModal}
          additional={ADDITIONAL_VOUCHER.whBillItem}
          cancelBill 
          totalMoneyCancel={totalMoneyCancel}
          blocks={blocks}
          whAppointment={whAppointments}
          isShowBlock
        />
      </Modal>
      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onCloseReceiptVouchersModal}
        onOk={() => {}}
        title={`Phiếu thu - Đơn hàng ${get(whBill, 'billNumber')} - ${get(
          whBill,
          'customerAddress.fullName'
        )}`}
        visible={isOpenReceiptVouchersModal}
        width={1500}
      >
        <WhReceiptVoucherForm
          whBill={whBill}
          whBillItem={whBillItem}
          onCancel={onCloseReceiptVouchersModal}
          additional={ADDITIONAL_VOUCHER.whBillItem}
        />
      </Modal>
      <Modal
        title={'Bạn có muốn huỷ các buổi hẹn còn lại của đơn hàng này ?'}
        visible={isOpenConfirmCancel}
        onCancel={() => onCloseConfirmCancel()}
        destroyOnClose
        footer={null}
      >
        <ModalConfirmCancel
          onCloseConfirmCancel={onCloseConfirmCancel}
          whBillItemId={id}
          whBillId={get(whBillItem, 'whBillId')}
          setWhAppointments={setWhAppointments}
          whAppointments={whAppointments}
          onOpenPaymentVouchersModal={onOpenPaymentVouchersModal}
          setTotalMoneyCancel={setTotalMoneyCancel}
          note={note}
          setNote={setNote}
          setIsShowButtonCancel={setIsShowButtonCancel}
          setNoteShow={setNoteShow}
        />
      </Modal>
      <Modal
        title={'Nhập lý do hoàn thành đơn hàng'}
        visible={isOpenConfirmCompeleted}
        destroyOnClose
        onCancel={onCloseConfirmCompeleted}
        footer={null}
        width={700}
      >
        <ModalConfirmCompleted    
         setNoteCompleted={setNoteCompleted}
         handleCustomerCompletedWhBillItem={handleCustomerCompletedWhBillItem}
         whBillId={id}
         whAppointments={whAppointments}
         noteCompleted={noteCompleted}
         setNoteCompletedShow={setNoteCompletedShow}
         onCloseConfirmCompeleted={onCloseConfirmCompeleted}
         setWhAppointments={setWhAppointments}
         setWhBillItemData={setWhBillItemData}
         whBillItemData={whBillItemData}
        />
      </Modal>
    </div>
  )
}

export default WhBillItemUpdateAppointments;
