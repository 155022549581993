import React from 'react';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { PATH_APP } from './paths';

// Dashboard
import Dashboard from '../pages/Dashboard/index';

// Hospital
import Hospital from '../components/Hospital';
import CreateHospital from '../components/Hospital/Form';

// Hospital
import Staff from '../components/Staff';
import StaffForm from '../components/Staff/Form';

// WhBill
import WhBill from '../components/WhBill';
import WhBillsProcessing from '../components/WhBill/WhBillsProcessing';

// WhBillItem
import WhBillItemUpdateAppointments from '../components/WhBillItem/WhBillItemUpdateAppointments';

// WhService
import WhService from '../components/WhService';
import WhServiceForm from '../components/WhService/Form';

// WhCategory
import WhCategory from '../components/WhCategory';
import WhCategoryForm from '../components/WhCategory/Form';

// WhPartner
import WhPartner from '../components/WhPartner';
import WhPartnerForm from '../components/WhPartner/Form';

// Whpartner Transaction
import Transaction from '~/components/Transaction';

// WhReceipt
import WhVoucherTabs from '../components/WhVoucher';
import WhReceiptVoucherForm from '../components/WhVoucher/Form/WhReceiptVoucherForm';
import WhPaymentVoucherForm from '../components/WhVoucher/Form/WhPaymentVoucherForm';

// WhPartnerWorkspace
import WorkspaceDashboard from '../components/Workspace/WorkspaceDashboard';
import WorkspaceRegisterWorkingTime from '../components/Workspace/WorkspaceRegisterWorkingTime';
import WorkspaceAllTasks from '../components/Workspace/WorkspaceAllTasks';
import WorkspacePendingTasks from '../components/Workspace/WorkspacePendingTasks';
import WorkspaceManageTasks from '../components/Workspace/WorkspaceManageTasks';
import WorkspaceMyWorkforce from '../components/Workspace/WorkspaceMyWorkforce';
import WorkspaceInviteWhPartnerForm from '../components/Workspace/WorkspaceInviteWhPartnerForm';
import WorkspaceTimeTrain from '~/components/Workspace/WorkspaceTimeTrain';
import WorkspaceJobsHistory from '~/components/Workspace/WorkspaceJobsHistory';

// Appointment
import Appointment from '../components/Appointment';
import AppointmentsOfHospitalDeleted from '~/components/Appointment/AppointmentsOfHospitalDeleted';

// NewsCategory
import NewsCategory from '../components/NewsCategory';
import NewsCategoryForm from '../components/NewsCategory/Form';

// News
import News from '../components/News';
import NewsForm from '../components/News/Form';

// Config
import StaffGroup from '../components/StaffGroup';
import StaffGroupForm from '../components/StaffGroup/Form';

import Degree from '../components/Degree';
import DegreeForm from '../components/Degree/Form';

import Speciality from '../components/Speciality';
import SpecialityForm from '../components/Speciality/Form';

// Authentication related pages
import Branches from '../pages/Authentication/Branches';
import Login from '../pages/Authentication/Login';
import LoginWorkspace from '../pages/Authentication/LoginWorkspace';
import Logout from '../pages/Authentication/Logout';

// Branch
import Branch from '~/components/Branch';
import BranchDetail from '~/components/Branch/Detail';

// Partner
import Partners from '~/components/Partner';
import PartnerDetail from '~/components/Partner/Detail';

// Order
import PendingOrders from '~/components/Order/PendingOrders';
import AllOrders from '~/components/Order/AllOrders';

// Employee
import Employee from '~/components/Employee';

// User Employee
import UserEmployee from '~/components/UserEmployee';

// Partner Ship
import Partnership from '~/components/Partnership';

// Coupon
import Coupon from '~/components/Coupon';
import GiveCoupon from '~/components/GiveCoupon';

// Policy
import Policy from '~/components/Policy';

// Shipping Vendor
import ShippingVendor from '~/components/ShippingVendor';

//Sessions Of Day
import whSessionOfDay from '../components/WhSessionOfDay';
import whSessionOfDayForm from '../components/WhSessionOfDay/Form';

//Rate Config
import DegreeList from '../components/DegreeList';
import DegreeListForm from '../components/DegreeList/Form';

import Position from '../components/Position';
import PositionForm from '../components/Position/Form';

import Experience from '../components/Experience';
import ExperienceForm from '../components/Experience/Form';

//TableRanking
import TableRanking from '../components/TableRanking';
import TableRankingForm from '../components/TableRanking/Form';

//Evaluate
import Evaluate from '../components/Evaluate';

//CustomerType
import CustomerType from '../components/CustomerType';
import CustomerTypeForm from '~/components/CustomerType/Form';
// Service Booking Instructions
import ServiceBookingInstruction from '../components/ServiceBookingInstruction/indexClone.js'; // Create Clone because Bug sourceTree

//Customer Management
import WhCustomerManagement from '../components/WhCustomerManagement';

//Customer Import
import WhCustomersImport from '~/components/WhCustomerImport';
import WhPartnerImport from '~/components/WhPartnerImport';

//WhManagement
import WhManagement from '~/components/WhManagement';

//WhWorkNorm
import WhWorkNorm from '../components/WhWorkNorm';
import WhWorkNormForm from '../components/WhWorkNorm/Form';

// Hospital - list
import HospitalList from '~/components/HospitalList';
import HospitalListForm from '~/components/HospitalList/Form';

// Service Place
import ServicePlace from '~/components/ServicePlace';
import ServicePlaceForm from '~/components/ServicePlace/Form';

// Clinic Dashboard
import ClinicDashboard from '~/components/ClinicDashboard';

//Pharmacy Online Dashboard
import PharmacyOnlineDashboard from '~/components/PharmacyOnlineDashboard';

import POLICY, { CORE_ACTION, GROUP_POLICY } from '~/constants/policy';
import NotificationBotManagement from '~/components/NotificationBotManagement';
import WhBillOrderTemp from '~/components/WhBill/WhBillOrderTemp';
import WhBillOrderTempList from '~/components/WhBill/WhBillOrderTempList';
import Banner from '~/components/Banner';
import ServiceFamilyHealthyCare from '~/components/ServiceFamilyHealthyCare';
import WhPackageLevel from '~/components/WhPackageLevel';
import Pharmacy from '~/components/Partner/Pharmacy';
import Clinic from '~/components/Partner/Clinic';
import WhReportParter from '~/components/WhReport';
import WhReportCustomer from '~/components/WhReport/WhReportCustomer';
import WhReportSystem from '~/components/WhReport/WhReportSystem';
import Highlight from '~/components/WhSetting';

import WhTrainingCourse from '~/components/whTrainingCourse/WhTrainingCourse';

// REFERRAL
import ConfigCampaign from '~/components/Referral/ConfigCampaign';
import RewardItem from '~/components/Referral/RewardItem';
import PointSystem from '~/components/Referral/PointSystem';
import WorkSpaceWallet from '~/components/Workspace/WorkSpaceWallet';
import WhMedicines from '~/components/WhMedicines';
import TransactionPartner from '~/components/Workspace/TransactionPartner';
import Ranking from '~/components/Referral/Ranking';

//KEYWORD SEARCH
import KeywordSearch from '~/components/KeywordSearch';

import ExchangePoint from '~/components/Referral/ExchangePoint';
import AssociateApointment from '~/components/Appointment/AssociateApointment';
import Activity from '~/components/Referral/Activity';
import RequestVoucher from '~/components/RequestVoucher/RequestVoucher';
// Partner cancel Appointment 
import PartnerCancelAppointment from '~/components/PartnerCancelAppointment/PartnerCancelAppointment'


import ListWareHouse from '~/components/WareHouse/ListWareHouse/index.js';
import MedicalEquipments from '~/components/WareHouse/MedicalEquipment';
import ProductCatalogue from '~/components/WareHouse/ProductCatalogue';
import ProductistList from '~/components/WareHouse/ProductList';
import VoucherWareHouse from '~/components/WareHouse/VoucherWareHouse/VoucherWareHouse';
import Equipment from '~/components/Workspace/Equipment';
import WhContact from '~/components/WhContact';
import WhInfoCustomer from '~/components/WhCustomerManagement/InfoCustomer';

import Page403 from '~/components/Page403';
import PageNotFound from '~/components/PageNotFound';
import Home from '~/components/Home';
import History from '~/components/History';
import WhServiceBranch from '~/components/WhService/WhServiceBranch';
import ProductListByWarehouse from '~/components/WareHouse/ProductList/ProductListByWarehouse';

import ConfirmVoucherWarehouse from '~/components/WareHouse/ConfirmVoucherWarehouse';
import WarehouseVoucherWhPartner from '~/components/Workspace/WarehouseVoucherWhPartner';
import UnConfirmWhBill from '~/components/WhBill/UnConfirmWhBillTable';
import Depreciation from '~/components/WhBill/Depreciation';
import MyNotification from '~/components/MyNotification';
import DeliveryCoupon from '~/components/WareHouse/DeliveryCoupon';
import Referral from '~/components/WhPartner/Referral';
import ReferralCustomerManagement from '~/components/WhCustomerManagement/Referral/index';
import EquipmentPartner from '~/components/WhPartner/EquipmentPartner';
import IntroUsingRefCode from '~/components/Referral/IntroUsingRefCode';
import System from '~/components/System';
import WorkFlow from '~/components/WorkFlow';
import WorkList from '~/components/WorkFlow/WorkList';
import TaskItem from '~/components/WorkFlow/TaskItem';
import DetailHistoryLog from '~/components/WorkFlow/Detail/DetailHistoryLog';
import Sprint from '~/components/WorkFlow/sprint/Sprint';
import StatusConfig from '~/components/WorkFlow/StatusConfig';
import Gallery from '~/components/Gallery';
import Job from '~/components/Job';
import FormJob from '~/components/Job/FormJob';
import AddYoutube from '~/components/AddYoutube';
import ApplyJob from '~/components/ApplyJob';
import PositionJob from '~/components/PositionJob';
import TutorialJob from '~/components/TutorialJob';
import SettingTutorialJob from '~/components/SettingTutorialJob';
import RegisterWhPartnerConfig from '~/components/RegisterWhPartner/RegisterWhPartnerConfig';
import RegisterWhPartnerList from '~/components/RegisterWhPartner/RegisterWhPartnerList';
import RegisterWhPartnerTypeForm from '~/components/RegisterWhPartner/registerWhPartnerTypeForm';
import SayAboutUs from '~/components/SayAboutUs';
import CompanyJob from '~/components/CompanyJob';
import InfoCompany from '~/components/InfoCompany';
import CoreValue from '~/components/CoreValue';
import ListSoftware from '~/components/Software/ListSoftWare';
import ListTrial from '~/components/Software/ListTrial';
import Footer from '~/components/UiClient/Footer';
import SoftwareBenefit from '~/components/UiClient/SoftwareBenefit';
import SoftwareTarget from '~/components/UiClient/softwareTarget';
import AppDownload from '~/components/UiClient/AppDownload';
import JobBanner from '~/components/UiClient/JobBanner';
import JobQuestion from '~/components/jobQuestion';
import JobForm from '~/components/jobForm';
import Adv from '~/components/UiClient/Adv';
import ServiceByLocation from '~/components/ServiceByLocation';
import ServiceByLocationDetail from '~/components/ServiceByLocation/ServiceByLocationDetail';
import WhServiceBranchForm from '~/components/WhService/WhServiceBranch/WhServiceBranchForm';
const userRoutes = [
  { path: '/dashboard', component: Dashboard , permission : POLICY.READ_WHCHARTDASHBOARDHEALTHCARE },

  { path: PATH_APP.main.home, component: Home  },

  // Notification Bot Management
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whNotificationBotManagement.root,
    component: NotificationBotManagement,
    permission: POLICY.READ_NOTIFICATIONBOTMANAGER
  },


  // MY Notification
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.myNotification.root,
    component: MyNotification,
  },

  // SHIPPING VENDOR
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.phippingVendor.root,
    component: ShippingVendor,
    permission: POLICY.READ_SHIPPINGCOMPANY
  },

  // POLICY
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.policy.root,
    component: Policy,
    permission: POLICY.READ_POLICY
  },

  // COUPON
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.coupon.root,
    component: Coupon,
    permission: POLICY.READ_DISCOUNTCODE
  },
  {
    path: PATH_APP.coupon.give,
    component: GiveCoupon,
    permission: POLICY.READ_GIVECOUPON
  },

  // PARTNER SHIP
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.partnership.root,
    component: Partnership,
    permission: POLICY.READ_LEADINGPARTNER
  },

  // EMPLOYEE
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.employee.root,
    component: Employee,
    permission: POLICY.READ_EMPLOYEE
  },

  // USER
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.user.root,
    component: UserEmployee,
    // permission: POLICY.READ_USER, // i comment this line because now i must be use Permission Or
    permissionOr: get(GROUP_POLICY(CORE_ACTION.READ), 'MANAGE_USER')
  },

  // PARTNER
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.partner.root,
    component: Partners,
    permission: POLICY.READ_PARTNERCLINIC
  },

  {
    path: PATH_APP.partner.detail,
    component: PartnerDetail,
    permission: POLICY.READ_PARTNERCLINIC
  },

  // CLINIC
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.clinics.root,
    component: Clinic,
    permission: POLICY.READ_PARTNERCLINIC
  },
  // CLINIC
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.pharmacies.root,
    component: Pharmacy,
    permission: POLICY.READ_PHARMACY
  },

  // BRANCH
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.branch.root,
    component: Branch,
    permission: POLICY.READ_BRANCH
  },

  {
    path: PATH_APP.branch.branchDetail,
    component: BranchDetail,
    permission: POLICY.WRITE_BRANCH
  },
  {
    path: PATH_APP.branch.clinicDetail,
    component: BranchDetail,
    permission: POLICY.WRITE_BRANCH
  },

  // HOSPITAL
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.hospital.root,
    component: Hospital,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.hospital.create,
    component: CreateHospital,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.hospital.edit,
    component: CreateHospital,
    permission: POLICY.CMS
  },

  // STAFF
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.staff.root,
    component: Staff,
    permission: POLICY.READ_MEDICALCLINIC
  },
  {
    exact: true,
    path: PATH_APP.staff.create,
    component: StaffForm,
    permission: POLICY.WRITE_MEDICALCLINIC
  },

  {
    exact: true,
    path: PATH_APP.staff.edit,
    component: StaffForm,
    permission: POLICY.UPDATE_MEDICALCLINIC
  },

  // APPOINTMENT
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.appointment.root,
    component: Appointment,
    permission: POLICY.READ_APPOINTMENT
  },

  // ASSOCIATE APPOINTMENT
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.associateApointment.root,
    component: AssociateApointment,
    permission: POLICY.READ_AFFILIATEDAPPOINTMENT
  },
  //  APPOINTMENTS OF HOSPITAL DELETED
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.appointment.deletedAppointment,
    component: AppointmentsOfHospitalDeleted,
    permission: POLICY.READ_AFFILIATEDAPPOINTMENT
  },

  // NEWS CATEGORY
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.newsCategory.root,
    component: NewsCategory,
    permission: POLICY.READ_ANNOUNCEMENT
  },

  {
    exact: true,
    path: PATH_APP.newsCategory.create,
    component: NewsCategoryForm,
    permission: POLICY.WRITE_ANNOUNCEMENT
  },

  {
    exact: true,
    path: PATH_APP.newsCategory.edit,
    component: NewsCategoryForm,
    permission: POLICY.UPDATE_ANNOUNCEMENT
  },

  // NEWS
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.news.root,
    component: News,
    permission: POLICY.READ_ANNOUNCEMENT
  },

  {
    exact: true,
    path: PATH_APP.news.create,
    component: NewsForm,
    permission: POLICY.WRITE_ANNOUNCEMENT
  },

  {
    exact: true,
    path: PATH_APP.news.edit,
    component: NewsForm,
    permission: POLICY.UPDATE_ANNOUNCEMENT
  },

  // ORDERS
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.order.pendingOrder,
    component: PendingOrders,
    permission: POLICY.ORDER
  },
  {
    exact: true,
    path: PATH_APP.order.allOrder,
    component: AllOrders,
    permission: POLICY.ORDER
  },

  // WH CATEGORY
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whCategory.root,
    component: WhCategory,
    permission: POLICY.READ_WHSERVICEGROUP
  },
  {
    exact: true,
    path: PATH_APP.whCategory.create,
    component: WhCategoryForm,
    permission: POLICY.WRITE_WHSERVICEGROUP
  },

  {
    exact: true,
    path: PATH_APP.whCategory.edit,
    component: WhCategoryForm,
    permission: POLICY.UPDATE_WHSERVICEGROUP
  },

  // WH BILL
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whBill.root,
    component: WhBill,
    permission: POLICY.READ_WHBILL
  },
  {
    exact: true,
    path: PATH_APP.whBill.processing,
    component: WhBillsProcessing,
    // permission: POLICY.READ_WHBILL,
    permissionOr: [POLICY.READ_WHBILL,POLICY.READ_WHAPPOINTMENT],
  },
  {
    exact: true,
    path: PATH_APP.whBill.orderTemp,
    component: WhBillOrderTempList,
    permission: POLICY.READ_QUOTATIONS
  },

  {
    exact: true,
    path: PATH_APP.whBill.depreciation,
    component: Depreciation,
    permission: POLICY.READ_WHBILL
  },

  // WH BILL ITEM
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whBillItem.updateAppointments,
    component: WhBillItemUpdateAppointments,
    permission: POLICY.READ_WHAPPOINTMENT
  },

  // WH SERVICE
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whService.root,
    component: WhService,
    permission: POLICY.READ_WHSERVICE
  },
  {
    exact: true,
    path: PATH_APP.whService.create,
    component: WhServiceForm,
    permission: POLICY.WRITE_WHSERVICE
  },

  {
    exact: true,
    path: PATH_APP.whService.edit,
    component: WhServiceForm,
    permission: POLICY.UPDATE_WHSERVICE
  },

  // WH PARTNER
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whPartner.root,
    component: WhPartner,
    permission: POLICY.READ_WHPARTNER
  },
  {
    exact: true,
    path: PATH_APP.whPartner.create,
    component: WhPartnerForm,
    permission: POLICY.WRITE_WHPARTNER
  },

  {
    exact: true,
    path: PATH_APP.whPartner.edit,
    component: WhPartnerForm,
    permission: POLICY.UPDATE_WHPARTNER
  },
  // WH PARTNER TRANSACTION
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whTransaction.root,
    component: Transaction,
    permission: POLICY.READ_WHTRANSACTION
  },

  // WH PARTNER TRANSACTION
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whTransaction.root,
    component: Transaction,
    permission: POLICY.READ_WHPARTNER
  },

  // WH VOUCHER
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whVoucher.root,
    component: WhVoucherTabs,
    permission: POLICY.READ_WHVOUCHERS
  },
  {
    exact: true,
    path: PATH_APP.whVoucher.createWhReceiptVoucher,
    component: WhReceiptVoucherForm,
    permission: POLICY.WRITE_WHBILL
  },
  {
    exact: true,
    path: PATH_APP.whVoucher.editWhReceiptVoucher,
    component: WhReceiptVoucherForm,
    permission: POLICY.UPDATE_WHBILL
  },
  {
    exact: true,
    path: PATH_APP.whVoucher.createWhPaymentVoucher,
    component: WhPaymentVoucherForm,
    permission: POLICY.WRITE_WHBILL
  },
  {
    exact: true,
    path: PATH_APP.whVoucher.editWhPaymentVoucher,
    component: WhPaymentVoucherForm,
    permission: POLICY.UPDATE_WHBILL
  },

  // WH REPORT
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whReport.partner,
    component: WhReportParter,
    permission: POLICY.READ_WHREVENUEPARTNER
  },
  {
    exact: true,
    path: PATH_APP.whReport.customer,
    component: WhReportCustomer,
    permission: POLICY.READ_WHREVENUECUSTOMER
  },
  {
    exact: true,
    path: PATH_APP.whReport.system,
    component: WhReportSystem,
    permission: POLICY.READ_WHREVENUESYSTEM
  },

  // CONFIG
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.config.staffGroup,
    component: StaffGroup,
    permission: POLICY.READ_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.createStaffGroup,
    component: StaffGroupForm,
    permission: POLICY.WRITE_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.editStaffGroup,
    component: StaffGroupForm,
    permission: POLICY.UPDATE_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.degree,
    component: Degree,
    permission: POLICY.READ_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.createDegree,
    component: DegreeForm,
    permission: POLICY.WRITE_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.editDegree,
    component: DegreeForm,
    permission: POLICY.UPDATE_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.speciality,
    component: Speciality,
    permission: POLICY.READ_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.createSpeciality,
    component: SpecialityForm,
    permission: POLICY.WRITE_CONFIGURATION
  },

  {
    exact: true,
    path: PATH_APP.config.editSpeciality,
    component: SpecialityForm,
    permission: POLICY.UPDATE_CONFIGURATION
  },
  // SESSION OF DAY
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whSessionOfDay.root,
    component: whSessionOfDay
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.whSessionOfDay.createWhSessionOfDay,
    component: whSessionOfDayForm
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.whSessionOfDay.editWhSessionOfDay,
    component: whSessionOfDayForm
    // permission: POLICY.SETTINGS
  },

  // RATE CONFIG
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.rateConfig.degreeList,
    component: DegreeList
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.rateConfig.createDegreeList,
    component: DegreeListForm
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.rateConfig.editDegreeList,
    component: DegreeListForm
    // permission: POLICY.SETTINGS
  },

  // POSITION
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.rateConfig.position,
    component: Position,
    permission: POLICY.READ_WHPOSITION
  },

  {
    exact: true,
    path: PATH_APP.rateConfig.createPosition,
    component: PositionForm,
    permission: POLICY.WRITE_WHPOSITION
  },

  {
    exact: true,
    path: PATH_APP.rateConfig.editPosition,
    component: PositionForm,
    permission: POLICY.UPDATE_WHPOSITION
  },

  // EXPERIENCE
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.rateConfig.experience,
    component: Experience
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.rateConfig.createExperience,
    component: ExperienceForm
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.rateConfig.editExperience,
    component: ExperienceForm
    // permission: POLICY.SETTINGS
  },

  // TABLE RANKING
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.tableRanking.root,
    component: TableRanking
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.tableRanking.createTableRanking,
    component: TableRankingForm
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.tableRanking.editTableRanking,
    component: TableRankingForm
    // permission: POLICY.SETTINGS
  },

  // EVALUATE
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.evaluate.root,
    component: Evaluate
    // permission: POLICY.SETTINGS
  },

  // CUSTOMER TYPE
  // ----------------------------------------------------------------------
  // {
  //   exact: true,
  //   path: PATH_APP.customerType.root,
  //   component: CustomerType
  //   // permission: POLICY.SETTINGS
  // },

  {
    exact: true,
    path: PATH_APP.customerType.create,
    component: CustomerTypeForm
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.customerType.edit,
    component: CustomerTypeForm
    // permission: POLICY.SETTINGS
  },

  // Service Booking Instruction
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.serviceBookingInstruction.root,
    component: ServiceBookingInstruction
  },

  {
    exact: true,
    path: PATH_APP.serviceBookingInstruction.create,
    component: ServiceBookingInstruction
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.serviceBookingInstruction.edit,
    component: ServiceBookingInstruction
    // permission: POLICY.SETTINGS
  },
  // CHANGE BANNER HOME PAGE
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.banner.root,
    component: Banner
  },

  {
    exact: true,
    path: PATH_APP.gallery.root,
    component: Gallery
  },
  {
    exact: true,
    path: PATH_APP.addYoutube.root,
    component: AddYoutube
  },
  {
    exact: true,
    path: PATH_APP.sayAboutUs.root,
    component: SayAboutUs
  },
  {
    exact: true,
    path: PATH_APP.appDownload.root,
    component: AppDownload
  },

  {
    exact: true,
    path: PATH_APP.job.root,
    component: Job
  },
  {
    exact: true,
    path: PATH_APP.job.edit,
    component: FormJob
  },
  {
    exact: true,
    path: PATH_APP.job.create,
    component: FormJob
  },
  {
    exact: true,
    path: PATH_APP.applyJob.root,
    component: ApplyJob
  },
  {
    exact: true,
    path: PATH_APP.positionJob.root,
    component: PositionJob
  },
  {
    exact: true,
    path: PATH_APP.tutorialJob.root,
    component: TutorialJob
  },
  {
    exact: true,
    path: PATH_APP.settingTutorialJob.root,
    component: SettingTutorialJob
  },

  {
    exact: true,
    path: PATH_APP.companyJob.root,
    component: CompanyJob
  },
  {
    exact: true,
    path: PATH_APP.infoCompany.root,
    component: InfoCompany
  },
  {
    exact: true,
    path: PATH_APP.jobQuestion.root,
    component: JobQuestion
  },
  {
    exact: true,
    path: PATH_APP.jobForm.root,
    component: JobForm
  },
  {
    exact: true,
    path: PATH_APP.coreValue.root,
    component: CoreValue
  },
  {
    exact: true,
    path: PATH_APP.software.listSoftWare,
    component: ListSoftware
  },
  {
    exact: true,
    path: PATH_APP.software.listTrial,
    component: ListTrial
  },
  {
    exact: true,
    path: PATH_APP.uiClient.adv,
    component: Adv
  },

  {
    exact: true,
    path: PATH_APP.uiClient.footer,
    component: Footer
  },
  {
    exact: true,
    path: PATH_APP.uiClient.softwareBenefit,
    component: SoftwareBenefit
  },
  {
    exact: true,
    path: PATH_APP.uiClient.softwareTarget,
    component: SoftwareTarget
  },
  {
    exact: true,
    path: PATH_APP.uiClient.jobBanner,
    component: JobBanner
  },

  {
    exact: true,
    path: PATH_APP.registerWhPartner.config,
    component: RegisterWhPartnerConfig
  },
  {
    exact: true,
    path: PATH_APP.registerWhPartner.list,
    component: RegisterWhPartnerList
  },
  {
    exact: true,
    path: PATH_APP.registerWhPartner.typeForm,
    component: RegisterWhPartnerTypeForm
  },

  {
    exact: true,
    path: PATH_APP.banner.create,
    component: Banner
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.banner.edit,
    component: Banner
    // permission: POLICY.SETTINGS
  },
  //CHANGE PACKAGE LEVEL PAGE
  //-----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whPackageLevel.root,
    component: WhPackageLevel
  },

  // SERVICE FAMILY HEALTHY CARE
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.serviceFamilyHealthyCare.root,
    component: ServiceFamilyHealthyCare
  },

  {
    exact: true,
    path: PATH_APP.serviceFamilyHealthyCare.create,
    component: ServiceFamilyHealthyCare
    // permission: POLICY.SETTINGS
  },

  {
    exact: true,
    path: PATH_APP.serviceFamilyHealthyCare.edit,
    component: ServiceFamilyHealthyCare
    // permission: POLICY.SETTINGS
  },

  // CUSTOMER MANAGEMENT
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whCustomerManagement.root,
    component: WhCustomerManagement,
    permission: POLICY.READ_CUSTOMER
  },
  {
    exact: true,
    path: PATH_APP.whCustomerManagement.referral,
    component: ReferralCustomerManagement,
    permission: POLICY.READ_CUSTOMER
  },

  {
    exact: true,
    path: PATH_APP.whCustomerManagement.info, 
    component: WhInfoCustomer,
    permission: POLICY.READ_CUSTOMER
  },

  // CUSTOMER IMPORT
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whCustomerImport.root,
    component: WhCustomersImport,
    permission: POLICY.READ_POTENTIALCUSTOMER
  },

  {
    exact: true,
    path: PATH_APP.whCustomerImport.create,
    component: WhCustomersImport,
    permission: POLICY.WRITE_POTENTIALCUSTOMER
  },

  {
    exact: true,
    path: PATH_APP.whCustomerImport.edit,
    component: WhCustomersImport,
    permission: POLICY.UPDATE_POTENTIALCUSTOMER
  },

  {
    exact: true,
    path: PATH_APP.whManagement.root,
    component: WhManagement
    // permission: POLICY.CMS
  },

  // PARTNER IMPORT
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whPartnerImport.root,
    component: WhPartnerImport,
    permission: POLICY.READ_WHPARTNERIMPORT
  },

  {
    exact: true,
    path: PATH_APP.whPartnerImport.create,
    component: WhPartnerImport,
    permission: POLICY.WRITE_WHPARTNERIMPORT
  },

  {
    exact: true,
    path: PATH_APP.whPartnerImport.edit,
    component: WhPartnerImport,
    permission: POLICY.UPDATE_WHPARTNERIMPORT
  },
  // TRAINING COURSE
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whTrainingCourse.root,
    component: WhTrainingCourse
  },
  // REFERRAL
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.referral.ConfigCampaign,
    component: ConfigCampaign,
    permission: POLICY.READ_REFERRAL
  },
  {
    exact: true,
    path: PATH_APP.referral.RewardItem,
    component: RewardItem,
    permission: POLICY.READ_REFERRAL
  },
  {
    exact: true,
    path: PATH_APP.referral.PointSystem,
    component: PointSystem,
    permission: POLICY.READ_REFERRAL
  },
  {
    exact: true,
    path: PATH_APP.referral.Ranking,
    component: Ranking,
    permission: POLICY.READ_REFERRAL
  },
  {
    exact: true,
    path: PATH_APP.referral.ExchangePoint,
    component: ExchangePoint,
    permission: POLICY.READ_REFERRAL
  },
  {
    exact: true,
    path: PATH_APP.referral.Activity,
    component: Activity,
    permission: POLICY.READ_REFERRAL
  },

  {
    exact: true,
    path: PATH_APP.referral.RequestVoucher,
    component: RequestVoucher
  },

  {
    exact: true,
    path: PATH_APP.referral.IntroUsingRefCode,
    component: IntroUsingRefCode
  },

  // MEDICINES
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whMedicines.root,
    component: WhMedicines
  },

  // KEYWORD SEARCH
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.keywordSearch.root,
    component: KeywordSearch
  },

  // WH WORK NORM
  {
    exact: true,
    path: PATH_APP.whWorkNorm.root,
    component: WhWorkNorm,
    permission: POLICY.READ_WHJOBSTANDARD
  },

  {
    exact: true,
    path: PATH_APP.whWorkNorm.create,
    component: WhWorkNormForm,
    permission: POLICY.WRITE_WHJOBSTANDARD
  },

  {
    exact: true,
    path: PATH_APP.whWorkNorm.edit,
    component: WhWorkNormForm,
    permission: POLICY.UPDATE_WHJOBSTANDARD
  },
  {
    exact: true,
    path: PATH_APP.highlight.root,
    component: Highlight,
    permission: POLICY.READ_HIGHLIGHT
  },

  //HOSPITAL LIST
  {
    exact: true,
    path: PATH_APP.hospitalList.root,
    component: HospitalList
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.hospitalList.create,
    component: HospitalListForm
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.hospitalList.edit,
    component: HospitalListForm
    // permission: POLICY.CMS
  },

  // SERVICE PLACE
  {
    exact: true,
    path: PATH_APP.servicePlace.root,
    component: ServicePlace
  },
  {
    exact: true,
    path: PATH_APP.servicePlace.create,
    component: ServicePlaceForm
  },
  {
    exact: true,
    path: PATH_APP.servicePlace.edit,
    component: ServicePlaceForm
  },

  // Partner cancel appoitment
  {
    exact: true,
    path: PATH_APP.partnerCancelAppointment.root,
    component: PartnerCancelAppointment
  },
  
  // SYSTEM
  {
    exact: true,
    path: PATH_APP.system.root,
    component: System,
    permission: POLICY.READ_SYSTEM
  },

  // SERVICE PLACE
  {
    exact: true,
    path: PATH_APP.servicePlace.root,
    component: ServicePlace
  },
  {
    exact: true,
    path: PATH_APP.servicePlace.create,
    component: ServicePlaceForm
  },
  {
    exact: true,
    path: PATH_APP.servicePlace.edit,
    component: ServicePlaceForm
  },

  // CLINIC DASHBOARD
  {
    exact: true,
    path: PATH_APP.clinicDashboard.root,
    component: ClinicDashboard,
    permission: POLICY.CHART_CLINIC
  },

  // PHARMACY DASHBOARD
  {
    exact: true,
    path: PATH_APP.pharmacyOnlineDashboard.root,
    component: PharmacyOnlineDashboard,
    permission: POLICY.ORDER
  },


  //WH CONTACT

  {
    exact: true,
    path: PATH_APP.whContact.root,
    component: WhContact,
    permission: POLICY.READ_WHCONTACT,
  },

  { exact: true, path: '/branches', component: Branches },
  {
    exact: true,
    path: PATH_APP.medicalEquipments.root,
    component: MedicalEquipments
  },
  {
    exact: true,
    path: PATH_APP.productCatalogue.root,
    component: ProductCatalogue
  },
  {
    exact: true,
    path: PATH_APP.listWareHouse.root,
    component: ListWareHouse,
  },
  {
    exact: true,
    path: PATH_APP.productList.root,
    component: ProductistList
  },
  {
    exact: true,
    path: PATH_APP.productListByWareHouse.edit,
    component: ProductListByWarehouse,
    permission: POLICY.READ_PRODUCTDELIVERY
  },
  {
    exact: true,
    path: PATH_APP.voucherWareHouse.root,
    component: VoucherWareHouse
  },
  // HISTORY WH SERVICE
  {
    exact: true,
    path: PATH_APP.history.root,
    component: History
  },
  {
    exact: true,
    path: PATH_APP.whServiceBranch.root,
    component: WhServiceBranch
  },

  {
    exact: true,
    path: PATH_APP.whServiceBranch.create,
    component: WhServiceBranchForm
  },
  {
    exact: true,
    path: PATH_APP.whServiceBranch.edit,
    component: WhServiceForm
  },
  
  {
    exact: true,
    path: PATH_APP.confirmVoucherWareHouse.root,
    component: ConfirmVoucherWarehouse
  },

  // Error Page
  {  path : PATH_APP.errorAccess.unauthorized , component: Page403},
  
  // { 
  //   exact: true,
  //   path: PATH_APP.whServiceBranch.root,
  //   component: WhServiceBranch
  // },
  {
    exact:true,
    path:PATH_APP.deliveryCoupon.root,
    component: DeliveryCoupon,
  },
  {
    exact:true,
    path:PATH_APP.whPartner.referral,
    component: Referral,
  },
  {
    exact:true,
    path:PATH_APP.whPartner.equipment,
    component: EquipmentPartner,
  },
  
  {
    exact: true,
    path: PATH_APP.workFlow.root,
    component: WorkFlow,
  },
  {
    // exact: true,
    path: PATH_APP.workFlow.sprint,
    component: Sprint,
  },
  {
    exact: true,
    path: PATH_APP.workFlowDetail.edit,
    component: WorkList
  },
  {
    exact: true,
    path: PATH_APP.workTaskItem.edit,
    component: TaskItem
  },
  {
    exact: true,
    path: PATH_APP.workTaskHistory.edit,
    component: DetailHistoryLog
  },
  {
    exact: true,
    path: PATH_APP.statusConfig.root,
    component: StatusConfig
  },
  // SERVICE BY LOCATION
  {
    exact: true,
    path: PATH_APP.serviceByLocation.root,
    component: ServiceByLocation
  },
  {
    exact: true,
    path: PATH_APP.serviceByLocation.edit,
    component: ServiceByLocationDetail
  },

  // this route should be at the end of all other routes
  { path: '/',exact: true, component: () => <Redirect to={PATH_APP.main.home} /> },
  {  path : PATH_APP.errorAccess.pageNotFound , component: PageNotFound},
];

const whPartnerRoutes = [
  // WH PARTNER WORKSPACE
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.root,
    component: WorkspaceDashboard
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.registerWorkingTime,
    component: WorkspaceRegisterWorkingTime
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.allTasks,
    component: WorkspaceAllTasks
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.pendingTasks,
    component: WorkspacePendingTasks
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.manageTasks,
    component: WorkspaceManageTasks
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.myWorkforce,
    component: WorkspaceMyWorkforce
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.inviteWhPartner,
    component: WorkspaceInviteWhPartnerForm
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.timeTrain,
    component: WorkspaceTimeTrain
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.wallet,
    component: WorkSpaceWallet
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.jobsHistory,
    component: WorkspaceJobsHistory
    // permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.transactions,
    component: TransactionPartner
  },

  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.equipment,
    component: Equipment
  },

  {
    exact: true,
    path: PATH_APP.whPartnerWorkspace.warehouseVoucher,
    component: WarehouseVoucherWhPartner
  },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/login-workspace', component: LoginWorkspace }
];

export { userRoutes, whPartnerRoutes, authRoutes };
