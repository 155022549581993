import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  InputNumber,
  Button,
  Radio,
  Skeleton,
  Modal
} from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import PicturesWall from '~/components/Utils/PicturesWall';
import { LANGUAGE, requireRules } from '~/constants/defaultValue';
import {
  useBranches,
  useCreateBranch,
  useBranch,
  useInitBranch,
  useResetBranch,
  useUpdateBranch,
  usePartnerBranches
} from '~/hooks';
import './index.scss';
import { useSpecialities } from '~/hooks/speciality';
import Services from '~/components/Hospital/Form/Services';
import WorkingHours from '~/components/Hospital/Form/WorkingHours';
import AddressFormSection from '~/components/Common/AddressFormSection';
import ModulesFormItem from '~/components/Common/ModulesFormItem';
import { onFormAddressChange } from '~/utils/helper';
import { filterAcrossAccents } from '~/hooks/utils';
import { useParams } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import ApiKeyForm from './ApiKeyForm';
import { isEmpty, negate, pickBy, get } from 'lodash';

const { Option } = Select;

const FormItem = Form.Item;

const BranchForm = ({ isOpen, onClose, id,branchType }) => {
  const { id: partnerId } = useParams();
  const [partnerBranches] = usePartnerBranches(partnerId);
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState();
  const [language, setLanguage] = useState(LANGUAGE.VI);

  const [specialities, isSpecialitiesLoading] = useSpecialities();
  const [branches] = useBranches();
  const [isSubmitLoading, handleCreate] = useCreateBranch(onClose);
  const [, handleUpdate] = useUpdateBranch(onClose);

  const [branch, isLoading] = useBranch(id);
  const initBranch = useInitBranch(branch, id, isOpen,branchType);
  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  const parentBranches = partnerId ? partnerBranches : branches;

  useResetBranch();

  useEffect(() => {
    form.resetFields();
    const { logo, photos, address } = initBranch;

    if (logo) {
      setLogo(logo);
    }

    if (photos && photos.length) {
      setImages(photos);
    }

    if (address) {
      setCityCode(address.cityId);
      setDistrictCode(address.districtId);
    }
  }, [initBranch, form, id]);

  const onFinish = (values) => {
    const initValueWkHours = {
      endTime: "Invalid date",
      startTime: "Invalid date",
      isOpen: false
    }
    const branch = {
      ...values,
      workingHours: values.workingHours.map((hour, index) => (
        {
          ...initValueWkHours,
          ...pickBy({
            // ...hour,
            startTime: hour.startTime?.format('HH:mm') ?? '',
            endTime: hour.endTime?.format('HH:mm') ?? '',
          }, negate(isEmpty)),
          isOpen: hour.isOpen ?? false,

        weekDay: index,
        // startTime: hour.startTime.format('HH:mm') || "Invalid date",
        // endTime: hour.endTime.format('HH:mm') || "Invalid date"
      })),
      logo,
      photos: images.filter((img) => !!img),
      partnerId,
    };
    if (id) {
      handleUpdate({ ...branch, _id: id });
    } else {
      handleCreate(branch);
    }
  };
  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
      style={{ top: 40 }}
    >
      <div className="branch-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={(values) => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initBranch}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} chi nhánh
            </h4>
            <Radio.Group
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <Radio.Button
                className="branch-form__select-langue-btn"
                value={LANGUAGE.VI}
              >
                VI
              </Radio.Button>
              <Radio.Button
                className="branch-form__select-langue-btn"
                value={LANGUAGE.EN}
              >
                EN
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="branch-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên chi nhánh"
                name={['name', LANGUAGE.VI]}
                className={`${language === LANGUAGE.EN && 'hiden'}`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem
                label="Tên chi nhánh"
                name={['name', LANGUAGE.EN]}
                className={`${language === LANGUAGE.VI && 'hiden'}`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem label="Chi nhánh quản lý" name="parentId" rules={requireRules}>
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {(parentBranches || []).map(({ _id, name }) => (
                      <Option key={_id} value={_id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <Form.Item
                label="Phân loại"
                name="branchType"
                hidden
                initialValue={['PHARMACY']}
                // rules={requireRules} BE not required and un-follow field because update tenant 
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select disabled mode="multiple">
                    <Option value="PHARMACY" key="PHARMACY">
                      Pharmacy
                    </Option>
                    <Option value="CLINIC" key="Clinic">
                      Clinic
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="branch-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} />
            </Col>
          </Row>

          <AddressFormSection
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          />

          <Row className="branch-form__speciality" gutter={48}>
            <Col span={24}>
              <FormItem
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                label="Mô tả ngắn"
                name={['description', LANGUAGE.VI]}
                className={`branch-form__description ${
                  language === LANGUAGE.EN && 'hiden'
                }`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                label="Mô tả ngắn"
                name={['description', LANGUAGE.EN]}
                className={`branch-form__description ${
                  language === LANGUAGE.VI && 'hiden'
                }`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>
          <Row className="branch-form__speciality" gutter={48}>
            <Col span={24}>
              <FormItem
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                label="Chuyên khoa"
                name="speciality"
                // rules={[
                //   {
                //     // required: true,
                //     // message: 'Xin vui lòng chọn chuyên khoa!'
                //   }
                // ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    mode="multiple"
                    placeholder=""
                    style={{ width: '100%' }}
                    loading={isSpecialitiesLoading}
                    disabled={isSpecialitiesLoading}
                  >
                    {specialities.map(({ name, id }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Thời gian khám bệnh (phút)"
                name={['branchSettings', 'slotTime']}
                labelCol={{ sm: 24, md: 24, lg: 12 }}
                wrapperCol={{ sm: 24, md: 24, lg: 12 }}
              >
                {isLoading ? <Skeleton.Input active /> : <InputNumber />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Số lượng bệnh nhân tối đa"
                name={['branchSettings', 'capacityPerSlot']}
                labelCol={{ sm: 24, md: 24, lg: 12 }}
                wrapperCol={{ sm: 24, md: 24, lg: 12 }}
              >
                {isLoading ? <Skeleton.Input active /> : <InputNumber />}
              </FormItem>
            </Col>
          </Row>
          <Services />

          <WorkingHours form={form} />

          <Row className="branch-form__images">
            <h4 className="branch-form__images-title">Hình ảnh</h4>
            <PicturesWall
              onImagesChange={setImages}
              initFilelist={initBranch.photos}
            />
          </Row>

          {id && <ApiKeyForm apiKey={get(branch,'apiKey','')} branchId={get(branch,'_id')}/>}

          <Row className="branch-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default BranchForm;
