import requester from './requester';

const branch = {
  getAll: (query) => requester.get('/branch?partnerId=99999', query),
  getAllBranch: (query) => requester.get('/branch-all', query),
  getById: (id) => requester.get(`/branch/${id}?raw=true`),
  create: (branch) => requester.post('/branch', branch),
  update: (branch) => requester.put(`/branch/${branch._id}`, branch),
  delete: (id) => requester.delete(`/branch/${id}`),
  getResource: (payload) => requester.get(`/resource-branch-contractor`,payload),
  getResourceAccessed: (branchId) => requester.get(`/resource-branch`,{branchId}),
  accessResource : (payload) => requester.put(`/action-resource-branch`,payload),
  denyResource : (payload) => requester.delete(`/action-resource-branch`,payload),
  getCategoryBranch: () => requester.get('/wh-category-branch?limit=200'),
  assignCategoryBranch : (payload) => requester.post('/wh-category-branch',payload),
  removeCategoryBranch : (payload) => requester.delete('/wh-category-branch',payload),
};

export default branch;
