import * as Types from '../../constants/actionTypes';
  
  export const getHistories = query => ({
    type: Types.GET_HISTORIES_REQUEST,
    payload: query
  });

  export const getHistoriesServiceByLocation = query => ({
    type: Types.GET_HISTORIES_SERVICE_BY_LOCATION_REQUEST,
    payload: query
  });
