// import produce from 'immer';
// import { get } from 'lodash';
// import * as Types from '../../constants/actionTypes';

// import getPaging from '../../utils/getPaging';

// const initState = {
//   isLoading: false,
//   getHistoryFailed: null,
//   histories: [],
// };

// export default produce((state, { type, payload }) => {
//   switch (type) {
//     case Types.GET_HISTORIES_REQUEST:
//       state.isLoading = true;
//       state.getHistoryFailed = null;
//       return;

//     case Types.GET_HISTORIES_SUCCESS:
//       state.isLoading = false;
//       state.histories = get(payload, 'docs', []);
//       state.getHistoryFailed = null;
//       state.paging = getPaging(payload);
//       return;

//     case Types.GET_HISTORIES_FAILED:
//       state.isLoading = false;
//       state.histories = [];
//       state.getHistoryFailed = payload;
//       return;
    
//       default:
//         return;
//   };
// });



import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getHistoryFailed: null,
  histories: [],

  isLoadingServiceByLocation: false,
  getHistoryServiceByLocationFailed: null,
  historiesServiceByLocation: [],
  pagingHistoryServiceByLocation: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_HISTORIES_REQUEST:
      state.isLoading = true;
      state.getHistoryFailed = null;
      return;

    case Types.GET_HISTORIES_SUCCESS:
      state.isLoading = false;
      state.histories = get(payload, 'docs', []);
      state.getHistoryFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_HISTORIES_FAILED:
      state.isLoading = false;
      state.histories = [];
      state.getHistoryFailed = payload;
      return;

    case Types.GET_HISTORIES_SERVICE_BY_LOCATION_REQUEST:
      state.isLoadingServiceByLocation = true;
      state.getHistoryServiceByLocationFailed = null;
      return;

    case Types.GET_HISTORIES_SERVICE_BY_LOCATION_SUCCESS:
      state.isLoadingServiceByLocation = false;
      state.historiesServiceByLocation = get(payload, 'docs', []);
      state.getHistoryServiceByLocationFailed = null;
      state.pagingHistoryServiceByLocation = getPaging(payload);
      return;

    case Types.GET_HISTORIES_SERVICE_BY_LOCATION_FAILED:
      state.isLoadingServiceByLocation = false;
      state.historiesServiceByLocation = [];
      state.getHistoryServiceByLocationFailed = payload;
      return;
    
    case Types.RESET_STORE:
    case Types.RESET_HISTORY_STATE:
      return initState;

    default:
      return;
  }
}, initState);
